import { logoutUser } from './../../lib/utils';
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ConversationMessage } from "contracts/IConversationMessage";
import { CHAT_AI_PAGE, LOGIN_USER } from "lib/constants";
import { getConversationTitlesByUserId } from "services/apis/chatAiService";
import { getObjectFromStorage } from "services/storage/localStorageService";
import { Conversation, IUserConversationState } from "../types/iUserConversationContract";

const initialState: IUserConversationState = {
	titles: [],
	activeConversationId: -1,
	activeConversation: null,
	fetchingConversation: false
};

export const conversationSlice = createSlice({
	name: 'conersations',
	initialState,
	reducers: {
		setNewChatInReducer: (state) => {
			const conversationIndex = state.titles.findIndex(title => title.conversationId === -1);
			if (conversationIndex === -1) {
				state.titles.push({
					conversationId: -1,
					conversationTitle: 'New Chat',
					conversationMessages: []
				});
			}
			state.activeConversationId = -1;
			state.activeConversation = undefined;
		},
		setConversationTitles: (state, action: PayloadAction<Conversation[]>) => {
			state.titles = Array.isArray(action.payload) ? action.payload.reverse() : [];
		},
		setUserConversation: (state, action: PayloadAction<Conversation>) => {
			const { conversationId, conversationMessages } = action.payload;
			const conversationIndex = state.titles.findIndex(title => title.conversationId === conversationId);
			if (conversationIndex !== -1) {
				let conv: Conversation = state.titles[conversationIndex];
				state.titles[conversationIndex].conversationMessages = conversationMessages;
				state.activeConversationId = conversationId;
				state.activeConversation = conv;
			} else {
				// state.titles[conversationIndex].conversationMessages = [];
				state.activeConversationId = -1;
				state.activeConversation = undefined;
			}
		},
		updateUserConversation: (state, action) => {
			let conversationId = action.payload.conversationId;
			let convMsg: ConversationMessage = action.payload.response;

			if (state.activeConversationId === -1) {
				state.titles.unshift({
					conversationId: conversationId,
					conversationTitle: convMsg.message,
					conversationMessages: []
				});
			}
			state.titles = state.titles.map(title =>
				title.conversationId === -1 ? { ...title, conversationId: conversationId } : title
			);

			const conversationIndex = state.titles.findIndex(title => title.conversationId === conversationId);

			let conv: Conversation = state.titles[conversationIndex];
			state.titles[conversationIndex].conversationMessages.push(convMsg);

			state.activeConversationId = conversationId;
			state.activeConversation = conv;
			window.history.pushState({}, '', `${CHAT_AI_PAGE}/c/${conversationId}`);
		},
		getUserConversation: (state, action: PayloadAction<Conversation>) => {
			const { conversationId } = action.payload;
			const conversationIndex = state.titles.findIndex(title => title.conversationId === conversationId);
		},
		deleteUserConversation: (state, action) => {
			state.titles = state.titles.filter(conversation => conversation.conversationId !== action.payload);
			window.history.pushState({}, '', `${CHAT_AI_PAGE}`);
		},
		fetchConversationStart: (state) => { state.fetchingConversation = true },
		fetchConversationEnd: (state) => { state.fetchingConversation = false },
	},
	extraReducers: (builder) => {
		builder
			.addCase(getConversationTitles.fulfilled, (state, action) => {
				state.titles = Array.isArray(action.payload) ? action.payload.reverse() : [];
			})
	}
})

export const getConversationTitles = createAsyncThunk("conversation/getConversationTitles", async () => {
	let user = getObjectFromStorage(LOGIN_USER);

	return getConversationTitlesByUserId(user.id)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			console.log(error);
			if (error.response.status === 403) {
				logoutUser();
			}
		})
		.finally(() => { });
});

export const {
	setConversationTitles,
	setUserConversation,
	getUserConversation,
	deleteUserConversation,
	setNewChatInReducer,
	updateUserConversation,
	fetchConversationStart,
	fetchConversationEnd } = conversationSlice.actions;
export default conversationSlice.reducer;