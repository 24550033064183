import { Subscriptions } from 'contracts/homeInterface';
import React, { useState } from 'react';
import SubscriptionCard from './SubscriptionCard';
import './subscriptions_container.scss';

const SubscriptionsContainer: React.FC<{ subscriptions: Subscriptions }> = ({ subscriptions }) => {
	const [plan, setPlan] = useState(subscriptions.defaultSelected);
	const [isPlanHovered, setIsPlanHovered] = useState(false);

	const handlePlanMouseEnter = () => {
		setIsPlanHovered(true);
	};

	const handlePlanMouseLeave = () => {
		setIsPlanHovered(false);
	};

	return (
		<section className="subscriptions">
			<div className="subscription-container">
				<div className="subscription-plan-header">
					<h1>{subscriptions.headline}</h1>
					<p>{subscriptions.subheading}</p>
					<div className={`toggle-container ${plan}`}>
						<button
							className={plan === 'monthly' ? 'active' : ''}
							onClick={() => setPlan('monthly')}
						>
							Montly
						</button>
						<button
							className={plan === 'yearly' ? 'active' : ''}
							onClick={() => setPlan('yearly')}
						>
							Yearly (save {subscriptions.yearlyDiscount}%)
						</button>
						<div className="active-background"></div>
					</div>
				</div>
				<div className="our-subscriptions">
					{
						plan === 'yearly' ?
							subscriptions.subscriptionPlans.yearlyPlans.map((sub, index) => {
								return <SubscriptionCard
									handlePlanMouseEnter={handlePlanMouseEnter}
									handlePlanMouseLeave={handlePlanMouseLeave}
									index={index}
									isPlanHovered={isPlanHovered}
									subscriptionPlan={sub}
									plan={plan}
									yearlyDiscount={subscriptions.yearlyDiscount}
									key={index}
								/>
							})
							: subscriptions.subscriptionPlans.monthlyPlans.map((sub, index) => {
								return <SubscriptionCard
									handlePlanMouseEnter={handlePlanMouseEnter}
									handlePlanMouseLeave={handlePlanMouseLeave}
									index={index}
									isPlanHovered={isPlanHovered}
									subscriptionPlan={sub}
									plan={plan}
									yearlyDiscount={subscriptions.yearlyDiscount}
									key={index}
								/>
							})
					}
				</div>
			</div>
		</section>
	)
}

export default SubscriptionsContainer