import React from 'react'
import './our_services.scss'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ServicesProvided } from 'contracts/homeInterface'

const OurServicesComponent: React.FC<{ servicesProvided: ServicesProvided }> = ({ servicesProvided }) => {
	return (
		<section className="our-services">
			<div className="os-container">
				<div className="services-header">
					<h1>{servicesProvided.headline}</h1>
					<p>{servicesProvided.subHeading}</p>
				</div>
				<div className="services-grid noselect">
					{
						servicesProvided.services.map((service, index) => {
							return <div className="service-card" key={index}>
								<div className="service-card-header">
									<div className="service-icon">🌟</div>
									<h2 className="service-title">{service.headline}</h2>
								</div>
								<p className="service-description">{service.text}</p>
								<a href={service.available ? service.url : undefined} className="service-link">{service.available ? service.availableText : service.nonAvailableText} <FontAwesomeIcon icon={faArrowRight} /></a>
							</div>
						})
					}
				</div>
			</div>
		</section>
	)
}

export default OurServicesComponent