import { getloginUserInitials } from 'lib/utils'
import React from 'react'

const UserMessageQuery: React.FC<{ message: string }> = ({ message }) => {
	return (
		<div className='message-container user' >
			<div className={`message user-message`} style={{ lineHeight: '1.4em' }}>
				{message}
			</div>
			<div className='icon noselect'>
				<div style={{ color: "#fff", fontSize: "16px", letterSpacing: 1 }}>{getloginUserInitials()}</div>
			</div>
		</div>
	)
}

export default UserMessageQuery