import React from 'react'

const GetStarted = () => {
    return (
        <div>
            <a href='/get-started' onClick={() => localStorage.setItem('authForm', 'login')} className="cta-btn" >Get Started</a>
        </div>
    )
}

export default GetStarted