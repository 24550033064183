import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import botImg from 'assets/bot-img.png';
import './promoting_chatbot.scss';


const PromotingChatbot = () => {
    return (
        <section className="promoting-chatbot">
            <div className="pc-container">
                <span className="tag">Our Best Service 🔥</span>
                <h1>Providing a Chat Bot that every developer needs</h1>
                <div className="pc-promotion-box">
                    <div className="pc-left">
                        <h3>DevGene's AI Chat Bot</h3>
                        <p>
                            Providing a chat bot that fulfils the need of every Developer, Software engineer out there.
                        </p>
                        <a href="/" className="service-link">Chat Now <FontAwesomeIcon icon={faArrowRight} /></a>
                    </div>
                    <div className="pc-right">
                        <img src={botImg} alt="devgene chat bot img" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PromotingChatbot