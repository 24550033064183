/* eslint-disable jsx-a11y/anchor-is-valid */
import { faBars, faBell, faCartShopping, faChalkboardUser, faComments, faDashboard, faFileInvoiceDollar, faLaptopCode, faMoneyBills, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getLoggedImage } from 'lib/utils'
import { useEffect, useState } from 'react'
import './admin.scss'
import AssetsAndTools from './assets_n_tools/AssetsAndTools'
import DashboardHome from './dashboard_home/DashboardHome'
import { useLocation, useNavigate } from 'react-router-dom'

const AdminDashboard = () => {

	const [activeTab, setActiveTab] = useState('DASHBOARD_HOME');
	const [activeTabTitle, setActiveTabTitle] = useState('Dashboard');
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const tab = queryParams.get('active_tab') ?? '';
		changeActiveTab(tab);
	}, [])

	const updateActiveMenu = (e: any, changeTab: string = '') => {
		e.preventDefault();
		const allSideMenu = document.querySelectorAll('#sidebar .side-menu.top li');
		allSideMenu.forEach(item => {
			item.addEventListener('click', () => {
				allSideMenu.forEach(i => {
					if (i && i.parentElement) i.classList.remove('active');
				})
				item.classList.add('active');
			})
		});
		changeActiveTab(changeTab);
	}

	const changeActiveTab = (tab: string) => {
		if (tab === '') {
			setActiveTabTitle('Dashboard');
			setActiveTab('DASHBOARD_HOME');
			addQueryParamForActiveTab('DASHBOARD_HOME');
		} else if (tab === 'ASSETS_TOOLS_HOME') {
			setActiveTabTitle('Assets n Tools');
			setActiveTab('ASSETS_TOOLS_HOME');
			addQueryParamForActiveTab('ASSETS_TOOLS_HOME');
		} else {
			setActiveTabTitle('Dashboard');
			setActiveTab('DASHBOARD_HOME');
			addQueryParamForActiveTab('');
		}
	}

	const addQueryParamForActiveTab = (tab: string) => {
		const params = new URLSearchParams(location.search);
		params.set('active_tab', tab);
		navigate({ search: params.toString() });

		console.log(params);

	};

	// TOGGLE SIDEBAR
	const menuBar = document.querySelector('#content nav .menu-icon');
	const sidebar = document.getElementById('sidebar');

	menuBar?.addEventListener('click', function () {
		sidebar?.classList.toggle('hide');
	})

	const renderContent = () => {
		switch (activeTab) {
			case 'DASHBOARD_HOME':
				return <DashboardHome />;
			case 'ASSETS_TOOLS_HOME':
				return <AssetsAndTools />;
			default:
				return <DashboardHome />;
		}
	};

	return (
		<div className='admin-dashboard'>
			<section className="sidebar" id='sidebar'>
				<a href='/' className='brand'>
					<img src="logo.png" alt="Logo" />
				</a>

				<ul className='side-menu top'>
					<li className={`${activeTab === 'DASHBOARD_HOME' ? 'active' : ''}`}>
						<a href='#' onClick={(e) => updateActiveMenu(e)}>
							<FontAwesomeIcon icon={faDashboard} className='icon' />
							<span className='text'>Dashboard</span>
						</a>
					</li>
					<li className={`${activeTab === 'USERS_HOME' ? 'active' : ''}`}>
						<a href='#' onClick={(e) => updateActiveMenu(e, 'USERS_HOME')}>
							<FontAwesomeIcon icon={faUsers} className='icon' />
							<span className='text'>Users</span>
						</a>
					</li>
					<li className={`${activeTab === 'ASSETS_TOOLS_HOME' ? 'active' : ''}`}>
						<a href='#' onClick={(e) => updateActiveMenu(e, 'ASSETS_TOOLS_HOME')}>
							<FontAwesomeIcon icon={faCartShopping} className='icon' />
							<span className='text'>Tool Kits</span>
						</a>
					</li>
					<li className={`${activeTab === 'COURSES_HOME' ? 'active' : ''}`}>
						<a href='#' onClick={(e) => updateActiveMenu(e, 'COURSES_HOME')}>
							<FontAwesomeIcon icon={faChalkboardUser} className='icon' />
							<span className='text'>Courses</span>
						</a>
					</li>
					<li className={`${activeTab === 'SUBSCRIPTION_HOME' ? 'active' : ''}`}>
						<a href='#' onClick={(e) => updateActiveMenu(e, 'SUBSCRIPTION_HOME')}>
							<FontAwesomeIcon icon={faMoneyBills} className='icon' />
							<span className='text'>Subscriptions</span>
						</a>
					</li>
					<li className={`${activeTab === 'EXPERT_CHAT_HOME' ? 'active' : ''}`}>
						<a href='#' onClick={(e) => updateActiveMenu(e, 'EXPERT_CHAT_HOME')}>
							<FontAwesomeIcon icon={faComments} className='icon' />
							<span className='text'>Expert Chats</span>
						</a>
					</li>
					<li className={`${activeTab === 'DEV_HIRE_HOME' ? 'active' : ''}`}>
						<a href='#' onClick={(e) => updateActiveMenu(e, 'DEV_HIRE_HOME')}>
							<FontAwesomeIcon icon={faLaptopCode} className='icon' />
							<span className='text'>Dev Hire Requests</span>
						</a>
					</li>
					<li className={`${activeTab === 'REPORTS_HOME' ? 'active' : ''}`}>
						<a href='#' onClick={(e) => updateActiveMenu(e, 'REPORTS_HOME')}>
							<FontAwesomeIcon icon={faFileInvoiceDollar} className='icon' />
							<span className='text'>Reports</span>
						</a>
					</li>
				</ul>
			</section>
			<section id="content">
				<nav>
					<div className='active-nav'>
						<FontAwesomeIcon icon={faBars} className='menu-icon' />
						<a href="/" className="nav-link">Dashboard</a>
					</div>
					<div className='nav-icons'>
						<a href="#" className="notification">
							<FontAwesomeIcon icon={faBell} className='icon' />
							<span className="num">0</span>
						</a>
						<a href="#" className="profile">
							<img src={getLoggedImage()} alt='profile-img here' />
						</a>
					</div>
				</nav>
				<main>
					<div className="head-title">
						<h1>{activeTabTitle}</h1>
					</div>
					{renderContent()}
				</main>
			</section>

		</div>
	)
}

export default AdminDashboard