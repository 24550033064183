import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminItemActions from 'components/admin_item_actions/AdminItemActions';
import { ProductResponse } from 'contracts/IproductContract';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteProductById, getAllProducts } from 'services/apis/productService';

interface AllProductsProps {
	setSubActiveTab: React.Dispatch<React.SetStateAction<string>>;
	setSelectedItemId: React.Dispatch<React.SetStateAction<number>>;
	products: ProductResponse[];
	setProducts: React.Dispatch<React.SetStateAction<ProductResponse[]>>;
}

const AllProductsComponent: React.FC<AllProductsProps> = ({ setSelectedItemId, setSubActiveTab, products, setProducts }) => {

	// const [products, setProducts] = useState<ProductResponse[]>([]);
	const [menuVisible, setMenuVisible] = useState(false);
	const [activeItem, setActiveItem] = useState(0);

	const handleOptionClick = (option: string) => {
		console.log(`Option selected: ${option}`);
		switch (option) {
			case 'View':
				setSelectedItemId(activeItem);
				setSubActiveTab('view-product')
				break;
			case 'Edit':
				setSelectedItemId(activeItem);
				setSubActiveTab('edit-product')
				break;
			case 'Delete':
				if (window.confirm("Are you sure you want to delete this?")) {
					console.log('yes');
					const copy = products;
					setProducts(products.filter(prod => prod.productId !== activeItem))
					deleteProductById(activeItem)
						.then((res) => toast.success(res.data.message))
						.catch((error) => {
							toast.error(error.response.data.message);
							setProducts(copy);
						});
				}
				break;

			default:
				break;
		}
		setMenuVisible(false);
	};

	const toggleMenu = (index: number) => {
		setActiveItem(index)
		setMenuVisible(!menuVisible);
	};

	return (
		<div className="assets-tools-data">
			<div className='table-title'>
				<h3>Products</h3>
			</div>
			<table className='w100'>
				<thead className='w100'>
					<th className='w10'>ID</th>
					<th className='w20'>Name</th>
					<th className='w20'>Category</th>
					<th className='w20'>Thumbnail</th>
					<th className='w20'>Active</th>
					<th className='w10 actions'>Actions</th>
				</thead>
				<tbody>
					{
						products.map((product: ProductResponse, index: number) => {
							return <tr key={index}>
								<td className='w10'>{product.productId}</td>
								<td className='w20'>{product.productName}</td>
								<td className='w20'>{product.categoryName}</td>
								<td className='w20 img-box'>
									<img src={product.productThumbnailUrl} alt="product thumbnail" />
								</td>
								<td className='w20'>{product.active ? "Yes" : "No"}</td>
								<td className='w10 actions'>
									<FontAwesomeIcon icon={faEllipsisVertical} className='icon' onClick={() => toggleMenu(product.productId)} />
									{(menuVisible && activeItem === product.productId) && (
										<AdminItemActions handleOptionClick={handleOptionClick} setActiveItem={setActiveItem} setMenuVisible={setMenuVisible} />
									)}
								</td>
							</tr>
						})
					}
				</tbody>
			</table>
		</div>
	)
}

export default AllProductsComponent