import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface AppSpinnerProps {
    color?: string;
}

const AppSpinner: React.FC<AppSpinnerProps> = ({ color = '#fff' }) => {
    return (
        <FontAwesomeIcon className='icon spinner' icon={faSpinner} color={color} spin />
    )
}

export default AppSpinner