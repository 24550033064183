import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './assets_n_tools.scss'
import AddCategoryComponent from './components/AddCategoryComponent'
import AddProductComponent from './components/AddProductComponent'
import AllCategoriesComponent from './components/AllCategoriesComponent'
import AllProductsComponent from './components/AllProductsComponent'
import { ProductResponse } from 'contracts/IproductContract'
import { getAllProducts } from 'services/apis/productService'

const AssetsAndTools = () => {

	const navigate = useNavigate();
	const location = useLocation();
	const [subActiveTab, setSubActiveTab] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [products, setProducts] = useState<ProductResponse[]>([]);

	const [selectedItemId, setSelectedItemId] = useState<number>(-1);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getAllProducts();
				setProducts(response.data.data);
			} catch (error) {
				console.error('Error fetching products:', error);
			} finally {
			}
		};

		fetchData();
	}, []);

	const updateQueryParams = (key: string, value: string) => {
		setSubActiveTab(value)
		const searchParams = new URLSearchParams(location.search);
		searchParams.set(key, value);
		navigate({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};

	const handleDeleteCategory = (categoryId: number) => {
		setProducts(products.filter(product => product.categoryId !== categoryId));
	};

	const renderContent = () => {
		switch (subActiveTab) {
			case 'add-category':
				return <AddCategoryComponent isLoading={isLoading} viewMode={false} setIsLoading={setIsLoading} setSubActiveTab={setSubActiveTab} />;
			case 'view-category':
				return <AddCategoryComponent isLoading={isLoading} viewMode={true} setIsLoading={setIsLoading} setSubActiveTab={setSubActiveTab} selectedItemId={selectedItemId} />;
			case 'edit-category':
				return <AddCategoryComponent isLoading={isLoading} viewMode={false} setIsLoading={setIsLoading} setSubActiveTab={setSubActiveTab} selectedItemId={selectedItemId} />;
			case 'add-product':
				return <AddProductComponent isLoading={isLoading} viewMode={false} setIsLoading={setIsLoading} setSubActiveTab={setSubActiveTab} />;
			case 'view-product':
				return <AddProductComponent isLoading={isLoading} viewMode={true} setIsLoading={setIsLoading} setSubActiveTab={setSubActiveTab} selectedItemId={selectedItemId} />;
			case 'edit-product':
				return <AddProductComponent isLoading={isLoading} viewMode={false} setIsLoading={setIsLoading} setSubActiveTab={setSubActiveTab} selectedItemId={selectedItemId} />;
			default:
				return <div>
					<div className='filter-bar'>
						<button type='button' className='admin-add-btn' onClick={() => updateQueryParams('action', 'add-category')}>Add Category</button>
						<div style={{ width: '20px' }}></div>
						<button type='button' className='admin-add-btn' onClick={() => updateQueryParams('action', 'add-product')}>Add Product</button>
					</div>
					<div className='data-container'>
						<AllProductsComponent setSubActiveTab={setSubActiveTab} setSelectedItemId={setSelectedItemId} products={products} setProducts={setProducts} />
						<AllCategoriesComponent setSubActiveTab={setSubActiveTab} setSelectedItemId={setSelectedItemId} onDeleteCategory={handleDeleteCategory} />
					</div>
				</div>;
		}
	};

	return (
		<div className='assets-tools-container'>
			{renderContent()}
		</div >
	)
}

export default AssetsAndTools