import React from 'react'
import Carousel, { ArrowProps } from 'react-multi-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faQuoteRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { Testimonial } from 'contracts/homeInterface';
import './testimonials_container.scss'


const TestimonialsContainer: React.FC<{ testimonials: Testimonial[] }> = ({ testimonials }) => {

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 3
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 2
		},
		tablet: {
			breakpoint: { max: 1024, min: 0 },
			items: 1
		},
		mobile: {
			breakpoint: { max: 580, min: 0 },
			items: 1
		}
	};

	interface CustomArrowProps extends ArrowProps {
		onClick?: () => void;
	}

	const CustomRightArrow: React.FC<CustomArrowProps> = ({ onClick, ...rest }) => {
		return (
			<div className="arrow-forward pos-right" onClick={onClick}>
				<FontAwesomeIcon icon={faArrowRight} />
			</div>
		);
	};

	const CustomLeftArrow: React.FC<CustomArrowProps> = ({ onClick, ...rest }) => {
		return (
			<div className="arrow-forward pos-left" onClick={onClick}>
				<FontAwesomeIcon icon={faArrowLeft} />
			</div>
		);
	};

	return (
		<section className="testimonial">
			<div className="testimonial-slider">
				<Carousel containerClass="testimonial-carousel" responsive={responsive} showDots={false}
					itemClass="pad-right"
					transitionDuration={500}
					customLeftArrow={<CustomLeftArrow />}
					customRightArrow={<CustomRightArrow />}>
					{
						testimonials.map((review, index) => {
							return <div className="carousel-card" key={index}>
								<div className="user-icon">
									{/* <img src={dummyPersonImg} alt="rating user here" /> */}
									<FontAwesomeIcon icon={faQuoteRight} className="icon" />
								</div>
								<h4>{review.title}</h4>
								<p className="review-text">{review.review}</p>
								<p className="review-person">{review.personName + ', ' + review.role}</p>
								<div className="rating"><FontAwesomeIcon icon={faStar} className="icon" /> {review.rating}</div>
							</div>;
						})
					}
				</Carousel>
			</div>
			<div className="testimonial-about">
				<span className="tag">Testimonial From User 🌟</span>
				<h1>
					What our users say about us
				</h1>
				<p>Don't just trust our word, Listen to what our users are saying.</p>
				<button className="cta_button_outline">
					Get Started
				</button>
			</div>
		</section>
	)
}

export default TestimonialsContainer