
import React from 'react'
import './request_sending_loader.scss'

const RequestSendingLoader: React.FC<{ text?: string }> = ({ text }) => {
	return (
		<div className='rs-loader noselect'>
			<img src="./ring-loader.svg" alt="loader" />
			<p>{text || 'Updating...'}</p>
		</div>
	)
}

export default RequestSendingLoader