import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryResponse } from 'contracts/IcategoryContract';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllActiveCategories } from 'services/apis/categoryService';

interface FilterComponentProps {
	onFilterChange: (selectedCategories: number[], pageNum: number) => void;
	categoryFilterRef: any;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	isVisible: boolean;
}

const AssetProductFilterComponent: React.FC<FilterComponentProps> = ({ onFilterChange, categoryFilterRef, isVisible, setIsVisible }) => {
	const [categories, setCategories] = useState<CategoryResponse[]>([]);
	const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		getAllActiveCategories()
			.then(response => {
				setCategories(response.data.data);
			})
			.catch(error => {
				console.error('Error fetching categories:', error);
			});
	}, []);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const categoryParams = params.get('category');
		const page = params.get('page');
		if (categoryParams) {
			const categoryArray = categoryParams.split(',').map(Number);
			setSelectedCategories(categoryArray);
			onFilterChange(categoryArray, page ? parseInt(page) : 1);
		}
	}, [location.search]);

	const handleCheckboxChange = (categoryId: number) => {
		const params = new URLSearchParams(location.search);
		const page = params.get('page');

		let updatedCategories: number[] = [];
		if (selectedCategories.includes(categoryId)) {
			updatedCategories = selectedCategories.filter(id => id !== categoryId);
		} else {
			updatedCategories = [...selectedCategories, categoryId];
		}

		setSelectedCategories(updatedCategories);
		onFilterChange(updatedCategories, page ? parseInt(page) : 1);

		// Update URL parameters
		if (updatedCategories.length > 0) {
			params.set('category', updatedCategories.join(','));
		} else {
			params.delete('category');
		}
		navigate({ search: params.toString() });
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (categoryFilterRef.current && !categoryFilterRef.current.contains(event.target as Node)) {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		if (isVisible) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isVisible]);

	return (
		<div className={`assets_filter ${isVisible ? 'visible' : ''}`} ref={categoryFilterRef}>
			<div className='header'>
				<h3>Categories</h3>
				<FontAwesomeIcon icon={faClose} size='xs' className='filter_icon' onClick={() => setIsVisible(false)} />
			</div>
			{categories.map(category => (
				<div key={category.id} className='filter-item'>
					<input
						type="checkbox"
						id={`category-${category.id}`}
						checked={selectedCategories.includes(category.id)}
						onChange={() => handleCheckboxChange(category.id)}
					/>
					<label htmlFor={`category-${category.id}`}>{category.name}</label>
				</div>
			))}
		</div>
	)
}

export default AssetProductFilterComponent