import { CategoryRequest, CategoryResponse } from 'contracts/IcategoryContract';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCategoryById, saveCategory } from 'services/apis/categoryService';

interface AddCategoryProps {
	isLoading: boolean;
	viewMode: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setSubActiveTab: React.Dispatch<React.SetStateAction<string>>;
	selectedItemId?: number;
}

const AddCategoryComponent: React.FC<AddCategoryProps> = ({ isLoading, setIsLoading, setSubActiveTab, viewMode, selectedItemId = -1 }) => {
	const navigate = useNavigate();
	const initialProductState: CategoryRequest = {
		id: 0,
		name: '',
		active: false,
	};
	const [category, setCategory] = useState<CategoryRequest>(initialProductState);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		const { name, value, type } = e.target;
		setCategory((prevCategory: CategoryRequest) => ({
			...prevCategory,
			[name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
		}));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		console.log(category);
		try {
			const res = await saveCategory(category);
			if (res.data.statusCode === 200) {
				toast.success(res.data.message);
				goBack();
			}
			console.log(res);

		} catch (error: any) {
			console.log(error.response.data.message);
			toast.error(error.response.data.message);
		}
	};

	const goBack = () => {
		setSubActiveTab('');
		navigate('/dashboard');
	}

	useEffect(() => {
		let getCategory = async () => {
			try {
				const response = await getCategoryById(selectedItemId);
				let cat = response.data.data as CategoryResponse;
				setCategory({
					id: cat.id,
					name: cat.name,
					active: cat.active,
				})
				console.log(cat);
			} catch (error) {
				console.log(error);
			}
			console.log(selectedItemId);
		}
		if (selectedItemId !== -1) getCategory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='assets-tools-form'>
			<div className='item-actions-bar'>
				<h4>{viewMode ? 'Viewing' : (selectedItemId === -1 ? 'Add' : 'Edit')} Category</h4>
				<div className='filter-bar'>
					<button onClick={goBack} className='admin-add-btn'>Go Back</button>
					<div style={{ width: '20px' }}></div>
					<button onClick={handleSubmit} className='admin-add-btn'>Save</button>
				</div>
			</div>
			<form className='form-container'>
				<div className="form-fields">
					{
						category.id !== 0 &&
						<div className='form-group'>
							<label>
								Category ID
								<input name='id' readOnly={true} value={category.id} />
							</label>
						</div>
					}
					<div className='form-group'>
						<label>
							Category Name
							<input name="name" value={category.name} onChange={handleChange} placeholder='Enter Category Name' readOnly={viewMode} required />
						</label>
					</div>
					<div className='form-group checkbox'>
						<input type="checkbox" id="active" name="active" checked={category.active} onChange={viewMode ? () => { } : handleChange} readOnly={viewMode} />
						<label htmlFor="active">Active</label>
					</div>
				</div>
			</form>
		</div>
	)
}

export default AddCategoryComponent