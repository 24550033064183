import React, { useState } from "react";
import ChatAiPageSidebar from "./components/chatAiPageSidebar";
import ChatAiPageChatSection from "./components/chatAiPageChatSection";

import "./styles.scss";

const ChatAiPage = () => {
  const [sidebar, showSidebar] = useState(window.innerWidth <= 750 ? false : true);
  return (
    <div className="chat-ai-page">
      <div className={`${sidebar ? 'show' : 'hide'} sidebar`}>
        <ChatAiPageSidebar showSidebar={showSidebar} sidebar={sidebar} />
      </div>
      <div className="chat-section">
        <ChatAiPageChatSection showSidebar={showSidebar} sidebar={sidebar} />
      </div>
    </div>
  );
};

export default ChatAiPage;
