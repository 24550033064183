import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import UserAvatar from '../user-avatar';
import GetStarted from '../get-started';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { checkLoginStatus } from 'store/reducers/authReducer';
import { ASSETS_STORE_PAGE } from 'lib/constants';
import logoImg from 'assets/logo.png';

const Header = () => {
	const dispatch = useDispatch()

	const [showMenu, setShowMenu] = useState(false);
	const { isLoggedIn } = useSelector((state: RootState) => state.auth)

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	useEffect(() => {
		dispatch(checkLoginStatus())
	}, [dispatch]);

	return (
		<nav className="navbar">
			<div className="navbar-container">
				<div className="logo">
					<a href='/'>
						<img src={logoImg} alt="Logo" />
					</a>
				</div>
				<div className={`nav ${showMenu ? "show-menu" : "hide-menu"}`}>
					<div className="nav-items-container">
						<div className="nav-items">
							<a href="/">Home</a>
							<a href="/chat-ai">Chat AI</a>
							<a href={ASSETS_STORE_PAGE}>Tool Kit</a>
							{/* <a href="/courses">Courses</a> */}
							<a href="/contact-us">Contact Us</a>
							{/* 
							<a href="/store">Store</a>
							<a href="/about-us">About Us</a>
							 */}
						</div>
						<div className='user-login-status'>
							{isLoggedIn ? <UserAvatar classNames='ua-rounded' menuClass='top-dropdown-menu' /> : <GetStarted />}
						</div>
					</div>
					<div className="nav-close" id="nav-close" onClick={toggleMenu}>
						<FontAwesomeIcon icon={faClose} />
					</div>
				</div>
				<div className="nav-toggle" id="nav-toggle" onClick={toggleMenu}>
					<FontAwesomeIcon icon={faBars} />
				</div>
			</div>
		</nav>
	)
}

export default Header;



