import { faDownload, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import AppSpinner from 'components/app-spinner';
import Footer from 'components/footer/footer';
import Header from 'components/header/header';
import { ProductFeedback } from 'contracts/iFeedbackContract';
import { ProductResponse } from 'contracts/IproductContract';
import { AUTH_PAGE, LOGIN_USER } from 'lib/constants';
import { formatDate, getLoggedUserName } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addProductFeedback, getFeedbacksByProductId, getProductById, getProductDownloadUrl, getUserTodayDownloadQuota, updateUserDailyDownloadQuota } from 'services/apis/productService';
import { getObjectFromStorage } from 'services/storage/localStorageService';
import './user_view_asset_page.scss';
import { UserProductDownloadResponse } from 'contracts/IUserProductDownloadContract';

const UserViewAssetPage = () => {
	const [images, setImages] = useState<string[]>([]);
	const [userDownloadQuota, setUserDownloadQuota] = useState<UserProductDownloadResponse>();

	const { id } = useParams<{ id: string }>();
	const loggedInUser = getObjectFromStorage(LOGIN_USER);
	const history = useNavigate();

	const [product, setProduct] = useState<ProductResponse>();
	const [reviews, setReviews] = useState<ProductFeedback[]>([]);

	const [selectedImage, setSelectedImage] = useState<string>();
	const [currentIndex, setCurrentIndex] = useState(0);

	const [downloading, setDownloading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	const fetchProduct = () => {
		if (!id) {
			history('/all');
			return;
		}

		setLoading(true);
		setSelectedImage('');
		setImages([])

		getProductById(parseInt(id))
			.then(response => {
				let prod: ProductResponse = response.data.data;
				setSelectedImage(prod.productThumbnailUrl)
				let arr = prod.productImages;
				arr.unshift(prod.productThumbnailUrl)
				setImages(arr);
				setProduct(prod);

				fetchFeedback();
			})
			.catch(error => console.error('Error fetching products:', error))
			.finally(() => setLoading(false));
	};

	const fetchFeedback = () => {
		getFeedbacksByProductId(parseInt(id ?? '0'))
			.then(response => {
				setReviews(response.data);
			})
			.catch(error => console.error('Error fetching product reviews:', error))
			.finally();
	}

	useEffect(() => {
		fetchProduct()
	}, []);

	useEffect(() => {
		handleTodayDownloadQuotaFetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTodayDownloadQuotaFetch = () => {
		if (loggedInUser)
			getUserTodayDownloadQuota(loggedInUser.id).then((res) => setUserDownloadQuota(res.data.data))
	}

	const handleDownloadClick = async (itemId: number, url: string) => {
		if (userDownloadQuota && userDownloadQuota.todayDownloadsLeft > 0) {
			if (!url) {
				toast.error("No download link found for the tool.");
				return;
			}
			setDownloading(true);
			window.open(url, '_blank');
			updateUserDailyDownloadQuota(loggedInUser.id, itemId)
				.then((res) => setUserDownloadQuota(res.data.data))
				.finally(() => {
					setDownloading(false);
				});
		} else {
			toast.error("Max Downloads Reached for today. Please wait for the next day.");
		}
	};

	const handleNext = () => {
		const newIndex = (currentIndex + 1) % images.length;
		setCurrentIndex(newIndex);
		setSelectedImage(images[newIndex]);
	};

	const handlePrev = () => {
		const newIndex = (currentIndex - 1 + images.length) % images.length;
		setCurrentIndex(newIndex);
		setSelectedImage(images[newIndex]);
	};

	const initialFeedback = {
		userName: getLoggedUserName(),
		rating: 0,
		reviewText: '',
		productId: parseInt(id ?? '0')
	}

	const [newReview, setNewReview] = useState<ProductFeedback>(initialFeedback);

	const handleFeedbackDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value, type } = e.target;

		setNewReview((prevFeedback: ProductFeedback) => ({
			...prevFeedback,
			[name]: value,
		}));
	};

	const handleSetRating = (rating: number) => {
		setNewReview((prevFeedback: ProductFeedback) => ({
			...prevFeedback,
			'rating': rating,
		}));
	}

	const handleFeedbackSubmit = () => {
		if (newReview.rating < 1 || newReview.reviewText.trim() === '') {
			toast.error('Rating and Review Text is required');
			return;
		}

		toast.info('Review Added. Thanks for providing feedback.');
		setReviews([newReview, ...reviews]);
		try {
			addProductFeedback(newReview);
			setNewReview(initialFeedback);
		} catch (error) {
			console.log('review not added', error);
		}
	}

	return (
		<div className="product_page">
			<Header />
			<div>
				<div className="product_page_container">
					<div className="image_section">
						<div className="large_image_container">
							<img src={selectedImage} alt="Selected sneaker" className="large_image" />
							<div className="carousel_controls">
								<button onClick={handlePrev} className="carousel_button">←</button>
								<button onClick={handleNext} className="carousel_button">→</button>
							</div>
						</div>
						<div className="thumbnail_section">
							{images.map((image, index) => (
								<img
									key={index}
									src={image}
									alt={`Sneaker thumbnail ${index + 1}`}
									className={`thumbnail ${selectedImage === image ? 'selected' : ''}`}
									onClick={() => {
										setSelectedImage(image);
										setCurrentIndex(index);
									}}
								/>
							))}
						</div>
					</div>
					<div className="details_section">
						<div className='details_section_header'>
							<h3 className="category">{product?.categoryName}</h3>
							<div className='daily_downloads_counter noselect'>
								<h5>Daily Downloads</h5>
								{
									loggedInUser
									&& <div className='remaining_count'>
										<p>Remaining</p>
										<p>{userDownloadQuota?.todayDownloadsLeft}/{userDownloadQuota?.maxDownloadsAllowed}</p>
									</div>
								}
							</div>
						</div>
						<h1 className="title">{product?.productName}</h1>
						<p className="description">{product?.productDescription}</p>

						{ }
						{
							loggedInUser
								? <a rel='noreferrer' onClick={() => handleDownloadClick(product?.productId!, product?.productResourceFileUrl!)}>
									<div className='dl_btn_and_rating_container'>
										<div className='download_btn'
										// onClick={() => handleDownloadClick(product?.productId!)}
										>
											<p>Download</p>
											<div className='download_btn_icon'>
												{downloading
													? <AppSpinner color='#3068a5' />
													: <FontAwesomeIcon icon={faDownload} size='1x' className='icon' />
												}
											</div>
										</div>
									</div>
								</a>
								: <div className='text_banner'>Login to Download. <a href={AUTH_PAGE}>Login Now</a></div>
						}
					</div>
				</div>

				<div className='divider'></div>
				<div className="review_container">
					<div className="section_header">
						<h1>Recent Reviews</h1>
						<p>See what our users have to say about us</p>
					</div>
					<div className="review_content">
						{loggedInUser
							? <div className="review_form">
								<h3>Add a review</h3>
								<div>
									<div className="rating">
										{Array.from({ length: 5 }).map((_, index) => {
											const currRating = index + 1;
											return <label key={index}>
												<input name='rating' onClick={() => handleSetRating(currRating)}
													type='radio' />
												<FontAwesomeIcon icon={faStar} size='1x' className='rating_icon'
													color={currRating <= newReview.rating ? 'gold' : '#333'} />
											</label>
										})}
									</div>
								</div>
								<textarea
									placeholder="Your review *"
									name='reviewText'
									value={newReview.reviewText}
									onChange={handleFeedbackDataChange}
								/>
								<button onClick={handleFeedbackSubmit} className='cta_button'>Add Review</button>
							</div>
							: <div className='rating_no_login'>Please Login to add a Review. <a href={AUTH_PAGE}>Login Now</a></div>
						}
						<div className="reviews">
							{reviews.length ? reviews.map((review, index) => (
								<div className="review_item" key={index}>
									<div className="item_image">
										<img src='/images/white_logo.png' alt="avatar" />
									</div>
									<div className="item_info">
										<h4 className="item_title">{review.userName} – {formatDate(review.createDate!)}</h4>
										<div className="item_rating">
											{Array.from({ length: 5 }).map((_, index) => {
												const currRating = index + 1;
												return <FontAwesomeIcon icon={faStar} size='xs' className='rating_icon'
													color={currRating <= review.rating ? 'gold' : '#333'} key={index} />
											})}
										</div>
										<p className="item_text">{review.reviewText}</p>
									</div>
								</div>
							))
								: <div className='rating_no_login'>No Reviews. Be the 1st one to provide feedback.</div>
							}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default UserViewAssetPage