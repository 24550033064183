import { faUsersSlash, faUsers, faPersonCirclePlus, faMoneyBill1, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getLoggedImage } from "lib/utils"

const DashboardHome = () => {
	return (
		<div className='dashboard-content'>
			<ul className="box-info">
				<li>
					<FontAwesomeIcon icon={faUsersSlash} className='icon' />
					<span className="text">
						<h3>0</h3>
						<p>Free Users</p>
					</span>
				</li>
				<li>
					<FontAwesomeIcon icon={faUsers} className='icon' />
					<span className="text">
						<h3>0</h3>
						<p>Paid Users</p>
					</span>
				</li>
				<li>
					<FontAwesomeIcon icon={faPersonCirclePlus} className='icon' />
					<span className="text">
						<h3>0</h3>
						<p>Today New Users</p>
					</span>
				</li>
				<li>
					<FontAwesomeIcon icon={faMoneyBill1} className='icon' />
					<span className="text">
						<h3>$0</h3>
						<p>Subscription Revenue</p>
					</span>
				</li>
			</ul>

			<div className="table-data">
				<div className="order">
					<div className="head">
						<h3>Recent Subscriptions</h3>
					</div>
					<table>
						<thead>
							<tr>
								<th>User</th>
								<th>Email</th>
								<th>Date Order</th>
								<th>Package</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<img src={getLoggedImage()} alt='icon here' />
									<p>John Doe</p>
								</td>
								<td>john.doe@gmail.com</td>
								<td>01-10-2021</td>
								<td>Basic</td>
							</tr>
							<tr>
								<td>
									<img src={getLoggedImage()} alt='icon here' />
									<p>John Doe</p>
								</td>
								<td>john.doe@gmail.com</td>
								<td>01-10-2021</td>
								<td>Pro</td>
							</tr>
							<tr>
								<td>
									<img src={getLoggedImage()} alt='icon here' />
									<p>John Doe</p>
								</td>
								<td>john.doe@gmail.com</td>
								<td>01-10-2021</td>
								<td>Elite</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="todo">
					<div className="head">
						<h3>News Letter Subscribers</h3>
					</div>
					<ul className="todo-list">
						<li>
							<p>john.doe@gmail.com</p>
							<FontAwesomeIcon icon={faEllipsisVertical} className='icon' />
						</li>
						<li>
							<p>john.doe@gmail.com</p>
							<FontAwesomeIcon icon={faEllipsisVertical} className='icon' />
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default DashboardHome