import Header from "components/header/header";
import React from "react";
import Footer from "components/footer/footer";
import "./cours.css";

const CoursesPage = () => {
  return (
    <div>
      <Header />
      <main>
        <div className="contaner">
          <div className="back-img-div">
            <img className="hader-back-img" src="3.jpg" alt="back-img" />
          </div>

          <div className="left">
            <div></div>
            <div></div>
          </div>
          <div className="right">
            <div>
              <img className="cours-img" src="1.jpg" alt="img" />
              <p className="cours-p">
                Automate the Boring Stuff with Python Programming
              </p>
            </div>
            <div>
              <img className="cours-img" src="2.jpg" alt="img" />
              <p className="cours-p">
                The Complete 2024 Web Development Bootcamp
              </p>
            </div>
            <div>
              <img className="cours-img" src="3.jpg" alt="img" />
              <p className="cours-p">
                The Complete 2024 Web Development Bootcamp
              </p>
            </div>
            <div>
              <img className="cours-img" src="back.jpg" alt="img" />
              <p className="cours-p">
                The Ultimate 2024 Fullstack Web Development Bootcamp
              </p>
            </div>
            <div>
              <img className="cours-img" src="back.jpg" alt="img" />
              <p className="cours-p">
                The Ultimate 2024 Fullstack Web Development Bootcamp
              </p>
            </div>
            <div>
              <img className="cours-img" src="back.jpg" alt="img" />
              <p className="cours-p">
                The Ultimate 2024 Fullstack Web Development Bootcamp
              </p>
            </div>
            <div>
              <img className="cours-img" src="back.jpg" alt="img" />
              <p className="cours-p">
                The Ultimate 2024 Fullstack Web Development Bootcamp
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CoursesPage;
