import { faClose, faCloudUpload, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { CategoryResponse } from 'contracts/IcategoryContract';
import { ProductImages, ProductRequest, ProductResponse, ProductUploadProgress } from 'contracts/IproductContract';
import { ACCESS_TOKEN } from 'lib/constants';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllCategories } from 'services/apis/categoryService';
import { getProductById, getProductResourceTypes, getProductUploadProgress, saveProductFormData } from 'services/apis/productService';
import { getItemFromStorage } from 'services/storage/localStorageService';

interface AddProductProps {
	isLoading: boolean;
	viewMode: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setSubActiveTab: React.Dispatch<React.SetStateAction<string>>;
	selectedItemId?: number;
}

const AddProductComponent: React.FC<AddProductProps> = ({ isLoading, setIsLoading, setSubActiveTab, viewMode, selectedItemId = -1 }) => {
	const initialProductState: ProductRequest = {
		productId: 0,
		categoryId: 0,
		productName: '',
		productDescription: '',
		active: false,
		productThumbnail: '',
		productImages: [],
		productResourceType: '',
		productThumbnailFileName: '',
		productResourceFileName: '',
		isUploading: false,
		productThumbnailUrl: '',
		productResourceFileUploadPercentage: '0.00%'
	};

	const [product, setProduct] = useState<ProductRequest>(initialProductState);
	const [resourceFileName, setResourceFileName] = useState('');
	const [thumbnailFile, setThumbnailFile] = useState<File>();
	const [images, setImages] = useState<any>([]);
	const [toRemoveiImages, setToRemoveImages] = useState<any>([]);

	const [activeCategories, setActiveCategories] = useState<CategoryResponse[]>([]);
	const [uploadProgress, setUploadProgress] = useState<number>(0);
	const [productResourceFile, setProductResourceFile] = useState<File>();
	const [productResourceFileName, setProductResourceFileName] = useState<String>();

	const navigate = useNavigate();
	const goBack = () => {
		setSubActiveTab('');
		navigate('/dashboard');
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getAllCategories();
				setActiveCategories(response.data.data);

				setProduct((prevProduct: ProductRequest) => ({ ...prevProduct, categoryId: response.data.data[0].id }));
			} catch (error) {
				console.error('Error fetching categories:', error);
			} finally {
				console.log("Categories Fetched");
				setIsLoading(false);
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const getResourceTypes = async () => {
			try {
				const response = await getProductResourceTypes();
			} catch (error) {
				console.error('Error fetching ProductResourceTypes:', error);
			} finally {
				console.log("ProductResourceTypes Fetched");
			}
		};

		// getResourceTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		let intervalId: NodeJS.Timeout | null = null;

		if (product?.isUploading) {
			intervalId = setInterval(() => {
				getProductUploadProgress(product.productId).then((res) => {
					console.log(res);
					let progress: ProductUploadProgress = res.data;
					setProduct((prevProduct: ProductRequest) => ({
						...prevProduct,
						isUploading: progress.isUploading,
						productResourceFileUploadPercentage: progress.progress,
						productResourceFileUrl: progress.url
					}));
					if (!progress.isUploading) {
						setProductResourceFile(undefined);
						if (intervalId) {
							clearInterval(intervalId);
							intervalId = null;
						}
					}
				}).catch(error => {
					console.error('Error fetching product:', error);
					if (intervalId) {
						clearInterval(intervalId);
						intervalId = null;
					}
				});
			}, 1000);
		}

		return () => {
			if (intervalId) {
				toast.success("Tool Uploaded to azure!")
				clearInterval(intervalId);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product.isUploading]);

	useEffect(() => {
		let getProduct = async () => {
			try {
				const response = await getProductById(selectedItemId);
				let prod: ProductResponse = response.data.data;

				setProduct({
					productId: prod.productId,
					categoryId: prod.categoryId,
					productName: prod.productName,
					productDescription: prod.productDescription,
					active: prod.active,
					productThumbnail: prod.productThumbnailUrl,
					productImages: prod.productImages,
					productResourceType: prod.productResourceType,
					productThumbnailFileName: prod.productThumbnailFileName,
					productResourceFileName: prod.productResourceFileName,
					isUploading: prod.isUploading,
					productResourceFileUrl: prod.productResourceFileUrl,
					productThumbnailUrl: prod.productThumbnailUrl,
					productResourceFileUploadPercentage: prod.productResourceFileUploadPercentage
				})
				setImages(prod.productImages);
			} catch (error) {
				console.log(error);
			}
		}
		if (selectedItemId !== -1) getProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		const { name, value, type } = e.target;
		console.log(type, " , ", (e.target as HTMLInputElement).checked);

		setProduct((prevProduct: ProductRequest) => ({
			...prevProduct,
			[name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
		}));
	};

	const handleImagePickerClick = (element: string) => (document.querySelector(element) as HTMLInputElement)?.click();
	const handleResourcePickerClick = (element: string) => (document.querySelector(element) as HTMLInputElement)?.click();

	const handleImageChange = (event: any) => {
		const files = Array.from(event.target.files);
		if (!files.length) return;
		console.log(files.length + images.length);

		// debugger
		if (files.length + images.filter((i: any) => i !== '').length > 5) {
			alert('You can only upload a maximum of 5 images.');
			return;
		}
		// setImages(files);
		for (let image of images) {
			if (typeof image === 'string' && image !== '') {
				console.log(image);

			}
		}
		setImages((prevImages: any) => [...prevImages, ...files]);
	};

	const handleRemoveableImages = (event: any) => {
		const files = Array.from(event.target.files);
		if (!files.length) return;
		setToRemoveImages((prevImages: any) => [...prevImages, ...files]);
	};

	const removeImage = (index: number) => {
		setImages((prevImages: any) => {
			const updatedImages = prevImages.filter((_: any, i: number) => i !== index);
			const remove = prevImages.filter((_: any, i: number) => i === index);
			console.log(remove);

			if (remove && remove[0] && typeof remove[0] === 'string') {
				console.log('remoing azure image: ', remove[0]);
				setToRemoveImages((prevImages: any) => [...prevImages, ...remove]);
			}
			if (updatedImages.length < 5) {
				updatedImages.push('');
			}

			return updatedImages;
		});
	}

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		console.log(product);
		try {
			const formData = new FormData();

			if (product.productResourceFile) {
				formData.append('productResourceFile', product.productResourceFile);
			}
			formData.append('productResourceUrl', "");
			formData.append('productId', product.productId.toString());
			formData.append('categoryId', product.categoryId.toString());
			formData.append('productName', product.productName);
			formData.append('productDescription', product.productDescription);
			formData.append('active', product.active.toString());
			formData.append('productThumbnail', thumbnailFile!);
			formData.append('productThumbnailUrl', product.productThumbnailUrl);
			formData.append('productThumbnailFileName', product.productThumbnailFileName);
			formData.append('productResourceType', product.productResourceType);

			if (toRemoveiImages.length) {
				toRemoveiImages.forEach((file: any, index: number) => {
					formData.append(`azureProductImages`, file);
				});
			}
			if (images && images.length) {
				images.forEach((file: any, index: number) => {
					if (file !== '')
						formData.append(`productImages`, file);
				});
			}

			const res = await saveProductFormData(formData);
			if (res.data.statusCode === 200) {
				toast.success(res.data.message);
				goBack();
			}
			console.log(res);

		} catch (error: any) {
			console.log(error);

			toast.error(error.response.data.data);
		}
	};

	// File Upload
	const CHUNK_SIZE = 1024 * 1024 * 5;
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			setProductResourceFile(event.target.files[0]);
			setProductResourceFileName(event.target.files[0].name);
		}
	};

	const uploadFileInChunks = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		if (!productResourceFile) return;

		const totalChunks = Math.ceil(productResourceFile.size / CHUNK_SIZE);
		let uploadedSize = 0;
		let finalRes;

		try {
			for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
				const start = chunkIndex * CHUNK_SIZE;
				const end = Math.min(start + CHUNK_SIZE, productResourceFile.size);
				const chunk = productResourceFile.slice(start, end);

				const formData = new FormData();

				formData.append('file', chunk, productResourceFile.name);
				formData.append('chunkIndex', chunkIndex.toString());
				formData.append('totalChunks', totalChunks.toString());
				formData.append('productResourceFileName', productResourceFile.name);

				let res = await axios.post(`${process.env.REACT_APP_API_URL}/products/upload/${product.productId}`, formData, {
					headers: {
						'Authorization': `Bearer ${getItemFromStorage(ACCESS_TOKEN)}`
					},
					// eslint-disable-next-line no-loop-func
					onUploadProgress: (progressEvent) => {
						let progress = Math.round((uploadedSize + progressEvent.loaded) / productResourceFile.size * 100);
						if (progress >= 100) progress = 0;
						setUploadProgress(progress);
					}
				});
				finalRes = res;

				uploadedSize += chunk.size;
			}
			setProduct((prevProduct: ProductRequest) => ({
				...prevProduct,
				isUploading: true,
			}));
		} catch (e) {
			console.log(e);
		} finally {
			setUploadProgress(0);
		}
		console.log(finalRes);

	};

	return (
		<div className='assets-tools-form'>
			<div className='item-actions-bar'>
				<h4>{viewMode ? 'Viewing' : (product.productId === 0 ? 'Add' : 'Edit')} Product</h4>
				<div className='filter-bar'>
					<button onClick={goBack} className='admin-add-btn'>Go Back</button>
					<div style={{ width: '20px' }}></div>
					<button onClick={handleSubmit} className='admin-add-btn'>Save</button>
				</div>
			</div>
			<form className='form-container'>
				<div className="form-fields">
					{
						product.productId !== 0 &&
						<div className='form-group'>
							<label>
								Product ID
								<input name='productId' readOnly={true} value={product.productId} />
							</label>
						</div>
					}
					<div className='form-group'>
						<label>
							Product Name
							<input name="productName" value={product.productName} onChange={handleChange} placeholder='Enter Product Name' readOnly={viewMode} />
						</label>
					</div>
					<div className='form-group'>
						<label>
							Product Category
							<select id="my-select" name="categoryId" value={product.categoryId} onChange={handleChange} defaultValue={product.categoryId} disabled={viewMode}>
								{activeCategories.map((category) => (
									<option key={category.id} value={category.id}>
										{category.name}
									</option>
								))}
							</select>
						</label>
					</div>
					<div className='form-group checkbox'>
						<input
							type="checkbox"
							id="active"
							name="active" checked={product.active} onChange={viewMode ? undefined : handleChange} readOnly={viewMode}
						/>
						<label htmlFor="active">Active</label>
					</div>
					<div className='form-group'>
						<label>
							Product Description
							<textarea name="productDescription" value={product.productDescription} onChange={handleChange} placeholder='Enter Product Description' readOnly={viewMode} />
						</label>
					</div>
					{
						product.productId !== 0
							? <div className='form-group'>
								<label>
									Product Resource
									<input type='text' id='productResourceType' readOnly name="productResourceType" value={product.productResourceType} />
									{
										(product.productResourceFileUrl && product.productResourceFileUrl !== '')
											? <div className='text_banner'>{product.productResourceFileUrl}</div>
											: <div className='text_banner'>No File found, please upload</div>
									}
									{
										product.isUploading
											? <div className='text_banner'>Product upload to azure in progress {product.productResourceFileUploadPercentage}, Please wait.</div>
											: <div style={{ marginTop: '20px' }}>
												{uploadProgress > 0
													? <div>
														<progress value={uploadProgress} max="100"></progress>
														<span>Uploading to server {uploadProgress}%</span>
													</div>
													: <div>
														<div className='resource_picker' onClick={() => viewMode ? null : handleResourcePickerClick('#resource_picker')}>
															<input type='file' id='resource_picker' hidden name="productResourceFile" onChange={handleFileChange} />
															{
																productResourceFile
																	? <div className='resourceFileName'>{productResourceFileName}</div>
																	: <FontAwesomeIcon icon={faFileUpload} size={"2x"} />
															}
														</div>
														<button onClick={(e) => uploadFileInChunks(e)} type='button' className='admin-add-btn'>Upload</button>
													</div>
												}
											</div>
									}
								</label>
							</div>
							: <div className='text_banner'>Please save / create a new product to upload a file</div>
					}
				</div>
				<div className="form-images">
					<div>
						Product Thumbnail
						<div className='thumbnail-picker' onClick={() => viewMode ? null : handleImagePickerClick('#thumbnail-picker')}>
							<input type='file' accept='image/*' id='thumbnail-picker' hidden
								name="productThumbnail"
								onChange={(e: any) => {
									const files = e.target.files;
									if (files && files[0]) {
										setThumbnailFile(files[0]);
									}
								}}
							/>
							{
								product.productId !== 0 || thumbnailFile
									? (
										thumbnailFile
											? <img src={URL.createObjectURL(thumbnailFile)} height={200} alt={'Toolkit Thumbnail'} />
											: <img src={product.productThumbnail} height={200} alt={product.productName} />
									)
									: <FontAwesomeIcon icon={faCloudUpload} size={"3x"} />
							}
						</div>
					</div>
					<div style={{ marginTop: '20px' }}>
						Product Images
						<div className='images-container'>
							<div className="product-image image-picker" onClick={() => viewMode ? null : handleImagePickerClick('#image-picker')}>
								<input type='file' accept='image/*' id='image-picker' hidden multiple onChange={handleImageChange} />
								<FontAwesomeIcon icon={faCloudUpload} size={"1x"} />
							</div>
							{images.map((image: any, index: number) => (
								(typeof image === 'string')
									? (
										image === ''
											? <div key={`empty-${index}`} className="product_image"></div>
											: <div key={index} className="product_image">
												<img src={image} alt={`Selected ${index}`} height={100} />
												<FontAwesomeIcon icon={faClose} size='xs' color='red' className="remove_icon" onClick={() => removeImage(index)} />
											</div>
									)
									: <div key={index} className="product_image">
										<img src={URL.createObjectURL(image)} alt={`Selected ${index}`} height={100} />
										<FontAwesomeIcon icon={faClose} size='xs' color='red' className="remove_icon" onClick={() => removeImage(index)} />
									</div>
							))}
							{Array.from({ length: 5 - images.length }).map((_, index) => (
								<div key={`empty-${index}`} className="product_image"></div>
							))}
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}

export default AddProductComponent