import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./footer.scss";
import logo from 'assets/logo.png';
import { faFonticons, faLinkedin, faUpwork } from "@fortawesome/free-brands-svg-icons";
import { CONTACT_US_PAGE, FAQ_PAGE } from "lib/constants";

const Footer = () => {

  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="section section-1">
        <img src={logo} alt="logo here" />
        <h4>Empower Your Development Journey</h4>
        <p>&copy; {currentYear} DevGene AI. All rights reserved.</p>
      </div>
      <div className="section footer-links">
        <div className="section section-2">
          <h4>Products</h4>
          <div className="links">
            <a href="/">Chat Bot</a>
            <a href="/">Courses</a>
            <a href="/">Talk to an Expert</a>
            <a href="/">Visit Assets store</a>
            <a href="/">Hire a Developer</a>
          </div>
        </div>
        <div className="section section-3">
          <h4>Others</h4>
          <div className="links">
            <a href={CONTACT_US_PAGE}>Contact Us</a>
            <a href={FAQ_PAGE}>FAQ</a>
            {/* <a href="/">About Us</a> */}
          </div>
        </div>
      </div>
      <div className="section section-4">
        <h4>Social media</h4>
        <div className="icon-links">
          <div className="icon">
            <a href="https://www.linkedin.com/company/devgene/" target="_blank">
              <FontAwesomeIcon icon={faUpwork} />
            </a>
          </div>
          <div className="icon">
            <a href="https://www.linkedin.com/company/devgene/" target="_blank">
              <FontAwesomeIcon icon={faFonticons} />
            </a>
          </div>
          <div className="icon">
            <a href="https://www.linkedin.com/company/devgene/" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
