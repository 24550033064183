import { ProductFeedback } from "contracts/iFeedbackContract";
import { ProductRequest } from "contracts/IproductContract";
import { ACCESS_TOKEN } from "lib/constants";
import { getItemFromStorage } from "services/storage/localStorageService";
import { deleteRequest, getRequest, postRequest, setAuthToken, setMultiPartFormContentType } from "./baseRequests";

const controllerEndpoint = 'products';

export const saveProduct = (productData: ProductRequest) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return postRequest(`${controllerEndpoint}/`, productData);
}

export const saveProductFormData = (productData: FormData) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    setMultiPartFormContentType();
    return postRequest(`${controllerEndpoint}/saveProductFormData`, productData);
}

export const getAllProducts = () => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return getRequest(`${controllerEndpoint}/`);
}

export const getTopSixProducts = () => {
    return getRequest(`${controllerEndpoint}/topProducts`);
}

export const getProductResourceTypes = () => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return getRequest(`${controllerEndpoint}/getProductResourceTypes`);
}

export const getAllActiveProducts = (page: number, itemsPerPage: number, category?: string) => {
    let queryParams = `page=${page}&size=${itemsPerPage}`;
    if (category) queryParams += `&category=${category}`

    return getRequest(`${controllerEndpoint}/active?${queryParams}`);
}

export const getProductById = (id: number) => {
    return getRequest(`${controllerEndpoint}/get/${id}`);
}
export const getProductDownloadUrl = (id: number) => {
    return getRequest(`${controllerEndpoint}/download/${id}`);
}

export const searchProduct = (query: string) => {
    return getRequest(`${controllerEndpoint}/filtered?query=${query}`);
}

export const getProductCount = () => {
    return getRequest(`${controllerEndpoint}/getProductCount`);
}

export const deleteProductById = (id: number) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return deleteRequest(`${controllerEndpoint}/destroy/${id}`);
}

export const getProductUploadProgress = (id: number) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return getRequest(`${controllerEndpoint}/uploadProgress/${id}`);
}

// Feedback
export const getFeedbacksByProductId = (productId: number) => {
    return getRequest(`productFeedbacks/byProduct/${productId}`);
}

export const addProductFeedback = (productFeedback: ProductFeedback) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return postRequest(`productFeedbacks/save`, productFeedback);
}

//product download quota
export const getUserTodayDownloadQuota = (userId: number) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return getRequest(`${controllerEndpoint}/getTodayDownloadQuota/${userId}`);
}

export const updateUserDailyDownloadQuota = (userId: number, productId: number) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return postRequest(`${controllerEndpoint}/updateDownloadCount/${userId}/${productId}`, {});
}