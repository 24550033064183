import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import conversationReducer from './reducers/conversationReducer';

export type RootState = ReturnType<typeof store.getState>;
export const store = configureStore({
    reducer: {
        auth: authReducer,
        conversations: conversationReducer,
    },
})
