import { faBolt, faCalendarCheck, faLayerGroup, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import assetsStoreMainImage from 'assets/assetstore_main.jpg';
import Footer from 'components/footer/footer';
import Header from 'components/header/header';
import { CategoryResponse } from 'contracts/IcategoryContract';
import { ProductResponse } from 'contracts/IproductContract';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllActiveCategories } from 'services/apis/categoryService';
import { getTopSixProducts, getUserTodayDownloadQuota } from 'services/apis/productService';
import './assetsstore.scss';
import AssetProductCard from './components/AssetProductCard';
import { UserProductDownloadResponse } from 'contracts/IUserProductDownloadContract';
import { User } from 'contracts/userInterface';
import { LOGIN_USER } from 'lib/constants';
import { getObjectFromStorage } from 'services/storage/localStorageService';

const AssetsStorePage = () => {
	const [products, setProducts] = useState<ProductResponse[]>([]);
	const [categories, setCategories] = useState<CategoryResponse[]>([]);
	const [userDownloadQuota, setUserDownloadQuota] = useState<UserProductDownloadResponse>();
	const loggedInUser: User = getObjectFromStorage(LOGIN_USER);


	const navigate = useNavigate();
	const goToAllProductsPage = () => navigate('/assetstore/all');

	useEffect(() => {
		getTopSixProducts()
			.then(response => {
				setProducts(response.data.data);
			})
			.catch(error => {
				console.error('Error fetching products:', error);
			});
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getAllActiveCategories();
				setCategories(response.data.data);
			} catch (error) {
				console.error('Error fetching categories:', error);
			} finally {
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		handleTodayDownloadQuotaFetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTodayDownloadQuotaFetch = () => {
		if (loggedInUser)
			getUserTodayDownloadQuota(loggedInUser.id).then((res) => setUserDownloadQuota(res.data.data))
	}

	return (
		<div className='assets_store'>
			<Header />
			<main className='content'>
				<section className='hero_section'>
					<div className='hero_section_main'>
						<div className='hero_section_content'>
							<h1>Developer Toolkit</h1>
							<p>Providing comprehensive tools and resources for all your development needs.</p>

							<div className='hero_section_ctas'>
								<a href='#top_products'>
									<button className='cta_button'>Download Now</button>
								</a>
								<a href='#product_categories'>
									<button className='cta_button_outline'>Explore More</button>
								</a>
							</div>
						</div>
						<div className='hero_section_image'>
							<img src={assetsStoreMainImage} alt='assets store' />
						</div>
					</div>
					<div className='hero_section_stats'>
						<div className='hero_section_stats_box'>
							<h5>Over 50,000 downloads</h5>
							<p>Join thousands of satisfied developers who have boosted their productivity with our tools.</p>
						</div>
						<div className='hero_section_stats_box'>
							<h5>Overall 4.9/5⭐ Rating</h5>
							<p>Our users love us! Experience top-rated resources trusted by developers worldwide.</p>
						</div>
					</div>
				</section>
				<section className='what_we_offer_section'>
					<div className="container">
						<div className="what_we_offer_section_content">
							<h1>Top-Quality Developer Tools</h1>
							<p>Enhancing your projects with the best development resources.</p>
						</div>
						<div className="what_we_offer_section_features">
							<div className="feature">
								<div className="circle">
									<FontAwesomeIcon icon={faToolbox} className='feature_circle_icon' />
								</div>
								<h3>Premium Tools</h3>
								<p>High-quality resources to elevate your projects, including 3D models, UI templates, and more.</p>
							</div>
							<div className="feature">
								<div className="circle">
									<FontAwesomeIcon icon={faBolt} className='feature_circle_icon' />
								</div>
								<h3>Effortless Integration</h3>
								<p>Seamlessly incorporate our tools into your workflow with comprehensive documentation and support.</p>
							</div>
							<div className="feature">
								<div className="circle">
									<FontAwesomeIcon icon={faLayerGroup} className='feature_circle_icon' />
								</div>
								<h3>Varied Resources</h3>
								<p>Explore a wide range of tools for game and web development, tailored to meet diverse needs.</p>
							</div>
							<div className="feature">
								<div className="circle">
									<FontAwesomeIcon icon={faCalendarCheck} className='feature_circle_icon' />
								</div>
								<h3>Constant Updates</h3>
								<p>Stay current with regular updates, ensuring you always have access to the latest tools and features.</p>
							</div>
						</div>
					</div>
				</section>
				<section className='categories_section' id='product_categories'>
					<div className='categories_headline'>
						<h4>Browse Categories</h4>
						<p>Explore tools tailored for your development needs.</p>
					</div>
					<div className='categories_container'>
						{
							categories.map((category: CategoryResponse, index: number) => {
								return <a href={`/assetstore/all?category=${category.id}`} className='category_item' key={index}><div>{category.name}</div></a>
							})
						}
					</div>
				</section>
				<section className='top_assets_section' id='top_products'>
					<div className='top_assets_headline'>
						<h4>Top Products</h4>
						<p>Have a look at our most downloaded products</p>
					</div>
					<div className='top_assets_container'>
						{
							products.map((product, index: number) => {
								return <AssetProductCard key={index} assetData={product} userDownloadQuota={userDownloadQuota!} setUserDownloadQuota={setUserDownloadQuota} />
							})
						}
					</div>
					<div className='top_assets_view_all'>
						<button className='cta_button_outline' onClick={goToAllProductsPage}>View All</button>
					</div>
				</section>
			</main>
			<Footer />
		</div>
	)
}

export default AssetsStorePage