import { getRequest } from "./baseRequests";

const controllerEndPoint: string = '/home';

export const getLandingPageContenet = () => {
    return getRequest(`${controllerEndPoint}/getContent`);
}

export const getAiChatExamples = () => {
    return getRequest(`${controllerEndPoint}/chatExamples`);
}