import { ACCESS_TOKEN } from 'lib/constants';
import { UserPrompt } from '../../contracts/userPromptInterface';
import { deleteRequest, getRequest, postRequest, setAuthToken } from "./baseRequests";
import { getItemFromStorage } from 'services/storage/localStorageService';
import { MessageResponseDto } from 'contracts/iMessageResponse';


const controllerEndpoint = 'conversations';

export const getConversationTitlesByUserId = (userId: number) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return getRequest(`${controllerEndpoint}/${userId}/titles`)
}

export const getConversationMessagesByConversationId = (conversationId: number) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return getRequest(`${controllerEndpoint}/${conversationId}/messages`);
}

export const deleteConversationByConversationId = async (conversationId: number) => {
    return await deleteRequest(`${controllerEndpoint}/${conversationId}`);
}

export const sendUserPromptToAi = (userPromptData: UserPrompt) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return postRequest("/conversationMessages/chat", userPromptData);
}

export const saveMessageResponse = (messageResponse: MessageResponseDto) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return postRequest("/conversationMessages/saveMessage", messageResponse);
}