import React from 'react'

interface AuthInfoProps {
    showLogin: boolean;
    handleToggleForm: () => void;
}

const AuthInfo: React.FC<AuthInfoProps> = ({ showLogin, handleToggleForm }) => {
    return (
        <div className={`overlay-container `}>
            <div className="overlay">
                {showLogin ?
                    <div className="overlay-panel overlay-left">
                        <img className='login-img' src='login.png' alt='lo' />
                        <h1>Welcome Back!</h1>
                        <p>To keep connected with us please login with your personal info</p>
                        <p>OR</p>
                        <button className='cta_button_outline' style={{ borderColor: "white" }} onClick={() => handleToggleForm()}>Sign Up</button>
                    </div>
                    : <div className="overlay-panel overlay-right">
                        <img className='login-img' src='login.png' alt='lo' />
                        <h1>Hello, Friend!</h1>
                        <p>Enter your personal details and start journey with us</p>
                        <p>OR</p>
                        <button className='cta_button_outline' style={{ borderColor: "white" }} onClick={() => handleToggleForm()}>Sign In</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default AuthInfo