import axios, { AxiosError } from 'axios';
import { AUTH_PAGE } from 'lib/constants';
import { toast } from 'react-toastify';
import { logout } from 'store/reducers/authReducer';
import BASE_API_URL from './baseUrl';

const axiosInstance = axios.create({
    baseURL: BASE_API_URL,
    // headers: {
    //     'Content-Type': 'application/json',
    // },
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            handleUnauthorized(logout);
        }
        return Promise.reject(error);
    }
);

const handleUnauthorized = (logoutAction: () => void) => {
    logoutAction();
    localStorage.clear();
    toast.error("Your session timed out. Please login again!");
    window.location.href = AUTH_PAGE;
    window.location.reload();
};

export const setAuthToken = (token: string) => {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};


export const setMultiPartFormContentType = () => {
    axiosInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    console.log(axiosInstance.defaults.headers.common);
};

export const removeAuthToken = () => {
    delete axiosInstance.defaults.headers.common['Authorization'];
};

export const getRequest = (url: string) => {
    return axiosInstance.get(url);
};

export const postRequest = (url: string, data: any) => {
    return axiosInstance.post(url, data);
};

export const putRequest = (url: string, data: any) => {
    return axiosInstance.put(url, data);
};

export const deleteRequest = (url: string) => {
    // setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return axiosInstance.delete(url);
};

export const handleApiError = (error: any) => {
    if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const failedResponse: any = axiosError.response?.data;
        if (failedResponse?.message) {
            toast.error(failedResponse.message);
        } else {
            console.error('An error occurred:', error.message);
        }
    } else {
        console.error('An error occurred:', error.message);
    }
}

export default axiosInstance;
