import { LoaderSpinner } from "components/loader-spinner/LoaderSpinner";
import { HomeData } from "contracts/homeInterface";
import { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { getLandingPageContenet } from "services/apis/homeService";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import HeroSectionComponent from "./components/hero_section/HeroSectionComponent";
import NewsLetterComponent from "./components/news_letter_component/NewsLetterComponent";
import OurBestFeaturesComponent from "./components/our_best_features/OurBestFeatures";
import OurServicesComponent from "./components/our_services/OurServices";
import PromotingChatbotComponent from "./components/promoting_chatbot/PromotingChatbot";
import SubscriptionsContainer from "./components/subscriptions_container/SubscriptionsContainer";
import TestimonialsContainer from "./components/testimonials_container/TestimonialsContainer";
import { TopOfferingsComponent } from "./components/top_offerings/TopOfferings";
import './style.scss';

const HomePage = () => {

	const [homeData, setHomeData] = useState<HomeData | null>(null);
	const [loadingContent, setLoadingContent] = useState(true);
	const [transitionClass, setTransitionClass] = useState<string>('enter');

	useEffect(() => {
		getLandingPageContenet()
			.then((res) => {
				let data = res.data as HomeData;
				setHomeData(data);
				console.log(data);
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setTimeout(() => {
					setLoadingContent(false);
					setTransitionClass('exit');
				}, 1000);
			});
	}, []);

	return (
		<div className="bg-black">
			{loadingContent
				? <LoaderSpinner />
				: <div className={`landing-page-container ${transitionClass}`}>
					<Header />
					<div className="landing-page">
						<HeroSectionComponent heroSection={homeData?.heroSection!} />
						<TopOfferingsComponent topOfferings={homeData?.topOfferings!} />
						<OurBestFeaturesComponent aboutUs={homeData?.aboutUs!} />
						<OurServicesComponent servicesProvided={homeData?.servicesProvided!} />
						<PromotingChatbotComponent />
						<SubscriptionsContainer subscriptions={homeData?.subscriptions!} />
						<TestimonialsContainer testimonials={homeData?.testimonials!} />
						<NewsLetterComponent newsLetter={homeData?.newsLetter!} />
					</div>
					<Footer />
				</div>}
		</div>
	);
};

export default HomePage;
