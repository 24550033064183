import { faHome, faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ChatTopBar: React.FC<{ sidebar: boolean, showSidebar: (sidebar: boolean) => void; }> = ({ sidebar, showSidebar }) => {
	return (
		<div className="chat-topbar">
			<div className='page-title'>
				<FontAwesomeIcon className={`icon ${sidebar ? 'hide' : 'show'}`} onClick={() => showSidebar(!sidebar)} icon={faRightToBracket} />
				<h4>Chat Bot</h4>
			</div>
			<div className='chat-icons-list'>
				{/* <FontAwesomeIcon className='icon' icon={faMoon} /> */}
				<a href='/'><FontAwesomeIcon className='icon' icon={faHome} /></a>
			</div>
		</div>
	)
}

export default ChatTopBar