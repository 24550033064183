import { ACCESS_TOKEN } from "lib/constants";
import { getItemFromStorage } from "services/storage/localStorageService";
import { deleteRequest, getRequest, postRequest, setAuthToken } from "./baseRequests";
import { CategoryRequest } from "contracts/IcategoryContract";

const controllerEndpoint = 'categories';

export const getAllActiveCategories = () => {
    return getRequest(`${controllerEndpoint}/active`)
}

export const getAllCategories = () => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return getRequest(`${controllerEndpoint}/`)
}

export const getCategoryById = (id: number) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return getRequest(`${controllerEndpoint}/get/${id}`)
}

export const saveCategory = (category: CategoryRequest) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return postRequest(`${controllerEndpoint}/`, category);
}

export const deleteCategoryById = (id: number) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return deleteRequest(`${controllerEndpoint}/destroy/${id}`);
}