import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubscriptionPlan } from 'contracts/homeInterface';
import React, { MouseEventHandler } from 'react'

interface SubscriptionCardProps {
  subscriptionPlan: SubscriptionPlan;
  yearlyDiscount: number;
  isPlanHovered: boolean;
  index: number;
  plan: string;
  handlePlanMouseLeave: MouseEventHandler<HTMLDivElement>;
  handlePlanMouseEnter: MouseEventHandler<HTMLDivElement>;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = (
  { subscriptionPlan, isPlanHovered, index, yearlyDiscount, plan, handlePlanMouseEnter, handlePlanMouseLeave }
) => {

  const calcSubscriptionCost = (amount: number): string => {
    let monthCount: number = plan === 'yearly' ? 12 : 1;
    let discount: number = plan === 'yearly' ? (yearlyDiscount || 0) : 0;
    const totalCost = discount === 0 ? (amount * monthCount) : ((amount * monthCount) * ((100 - discount) / 100));
    return totalCost.toFixed(2);
  }

  return (
    <div className={`subscription-card ${(!isPlanHovered && index === 1) ? 'subscription-card-active' : ''}`} key={index} onMouseEnter={index === 1 ? undefined : handlePlanMouseEnter} onMouseLeave={index === 1 ? undefined : handlePlanMouseLeave}>
      <div className="about-plan">
        <h4>{subscriptionPlan.subscriptionDetail.planName}</h4>
        <p>{subscriptionPlan.subscriptionDetail.displayText}</p>
      </div>
      {
        plan === 'yearly' &&
        <div className="yearly-plan-pricing">
          <span className="dollar">$</span>{(subscriptionPlan.subscriptionDetail.planPrice * 12).toFixed(2)} <span className="duration">/ per year</span>
        </div>
      }
      <div className="plan-pricing">
        <span className="dollar">$</span>{calcSubscriptionCost(subscriptionPlan.subscriptionDetail.planPrice)} <span className="duration">/ per {plan === 'yearly' ? 'year' : 'month'}</span>
      </div>
      <div className="plan-details">
        <div className="pd-item">
          <FontAwesomeIcon className="icon" icon={faCheck} /><p>{subscriptionPlan.subscriptionDetail.chatBotText}</p>
        </div>
        <div className="pd-item">
          <FontAwesomeIcon className="icon" icon={faCheck} /><p>{subscriptionPlan.subscriptionDetail.coursesText}</p>
        </div>
        <div className="pd-item">
          <FontAwesomeIcon className="icon" icon={faCheck} /><p>{subscriptionPlan.subscriptionDetail.dailyToolText.replace('$VAL_HERE', subscriptionPlan.subscriptionDetail.subscriptionResources.dailyToolDownloadCount.toString())}</p>
        </div>
        <div className="pd-item">
          <FontAwesomeIcon className="icon" icon={faCheck} /><p>{subscriptionPlan.subscriptionDetail.askExpertText.replace('$VAL_HERE', subscriptionPlan.subscriptionDetail.subscriptionResources.expertQuestionCount.toString())}</p>
        </div>
      </div>
      <div className="get-plan-btn">Choose Plan</div>
    </div>
  )
}

export default SubscriptionCard