import React, { useEffect, useState } from 'react'

import './user_profile.scss'
import Header from 'components/header/header'
import { getLoggedImage } from 'lib/utils'
import { addObjectToStorage, getObjectFromStorage } from 'services/storage/localStorageService'
import { LOGIN_USER } from 'lib/constants'
import { UpdateProfileRequest, User } from 'contracts/userInterface'
import { updateUserProfile } from 'services/apis/userService'
import { useDispatch } from 'react-redux'
import { setLoginUser } from 'store/reducers/authReducer'
import { toast } from 'react-toastify'
import RequestSendingLoader from 'components/request_sending_loader/RequestSendingLoader'

const UserProfilePage = () => {

	const [loggedUser, setLoggedUser] = useState<User>(getObjectFromStorage(LOGIN_USER));

	const dispatch = useDispatch();
	const [email, setEmail] = useState(loggedUser.email);
	const [firstName, setFirstName] = useState(loggedUser.firstName);
	const [lastName, setLastName] = useState(loggedUser.lastName);
	const [isSending, setIsSending] = useState(false);


	const handleUserProfileUpdate = (e: any) => {
		e.preventDefault();
		console.log({ firstName, lastName, email });

		if (loggedUser.firstName !== firstName || loggedUser.lastName !== lastName) {
			setIsSending(true);
			let payload: UpdateProfileRequest = {
				id: loggedUser.id,
				firstName: firstName,
				lastName: lastName,
				email: email
			}

			updateUserProfile(payload)
				.then((res) => {
					console.log(res.data.data);
					dispatch(setLoginUser(res.data.data));
					addObjectToStorage(LOGIN_USER, res.data.data);
					setLoggedUser(res.data.data);
					toast.success(res.data.message)
				})
				.catch((err) => {
					console.log(err);
					toast.error("Unable to update User Profile.")
				})
				.finally(() => {
					setIsSending(false);
				});
		}
	}

	useEffect(() => { }, [loggedUser]);

	return (
		<div>
			<Header />
			<div className="user-profile-container">
				<div className='admin-header'>
					Account Settings
				</div>
				<div className='up-content'>
					<div className='user-details shadow'>
						<div className='image-container'>
							<img src={getLoggedImage()} alt='logged user' />
						</div>
						<div className='user-attributes'>
							<div className='attribute-box'>
								<h5>First Name</h5>
								<p>{loggedUser.firstName}</p>
							</div>
							<div className='attribute-box'>
								<h5>Last Name</h5>
								<p>{loggedUser.lastName}</p>
							</div>
							<div className='attribute-box'>
								<h5>Email</h5>
								<p>{loggedUser.email}</p>
							</div>
						</div>
					</div>
					<div className='user-form shadow'>
						<form onSubmit={handleUserProfileUpdate}>
							<div className='form-group'>
								<label>
									Email
									<input name='email' placeholder='Enter your Email' disabled={true} value={email} />
								</label>
								<span className='tooltip noselect'>Please contact support if you want to change your email.</span>
							</div>
							<div className='form-group'>
								<label>
									First Name
									<input name='firstName' placeholder='Enter your First Name' readOnly={false} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
								</label>
							</div>
							<div className='form-group'>
								<label>
									Last Name
									<input name='lastName' placeholder='Enter your Last Name' readOnly={false} value={lastName} onChange={(e) => setLastName(e.target.value)} />
								</label>
							</div>
							<div className='form-group'>
								{
									isSending
										? <RequestSendingLoader />
										: <button className='cta_button' type='submit'>Update Profile</button>
								}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UserProfilePage