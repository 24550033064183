import React, { useState } from 'react'
import { NewsLetter } from 'contracts/homeInterface';

import './news_letter_component.scss'
import parallaxImage from 'assets/parallax.jpg';

const NewsLetterComponent: React.FC<{ newsLetter: NewsLetter }> = ({ newsLetter }) => {
	const [email, setEmail] = useState('');

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		console.log(email);
	};

	return (
		<section className="news-letter" style={{ backgroundImage: `url(${parallaxImage})` }}>
			<div className="news-letter-container">
				<div className="header">
					<h1>{newsLetter.heading}</h1>
				</div>
				<div className="content">
					<div className="left">{newsLetter.subHeading}</div>
					<div className="form-container">
						<form onSubmit={handleSubmit}>
							<input
								type="email"
								className="email-input"
								placeholder="Email Address..."
								value={email}
								onChange={handleChange}
								required
							/>
							<button type="submit" className="submit-button">
								Submit
							</button>
						</form>
						<p className="note">No spam, ever. Unsubscribe anytime.</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default NewsLetterComponent