// AUTH Constants
export const ACCESS_TOKEN: string = "ACCESS_TOKEN";
export const REFRESH_TOKEN: string = "REFRESH_TOKEN";
export const LOGIN_USER: string = "LOGIN_USER";

// Route Constants
export const HOME_PAGE: string = '/';

export const AUTH_PAGE: string = "/get-started";
export const USER_PROFILE_PAGE: string = "/user-profile";
export const FORGOT_PASSWORD_PAGE: string = "/forgot-password";
export const LOGOUT_PAGE: string = "/logout";


export const CHAT_AI_PAGE: string = "/chat-ai";
export const CHAT_AI_DETAIL_PAGE: string = `${CHAT_AI_PAGE}/c`;
export const CHAT_DETAIL_PAGE: string = `${CHAT_AI_PAGE}/c/:chatId`;

export const CONTACT_US_PAGE: string = "/contact-us";
export const FAQ_PAGE: string = "/faq";
export const COURSES_PAGE: string = "/courses";

export const ASSETS_STORE_PAGE = "/assetstore";
export const ASSETS_STORE_ALL_PRODUCTS_PAGE = "/assetstore/all";
export const ASSETS_STORE_USER_VIEW_PRODUCT_PAGE = "/assetstore/all/:id";

/*
 * Dashboard Routes
 */

export const DASHBOARD_HOME_PAGE: string = '/dashboard';