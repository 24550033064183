import React from 'react'
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import TypewriterDG from './TypewriterDG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const BotMessageResponse: React.FC<{ response: string, isLastMessage?: boolean }> = ({ response, isLastMessage = false }) => {
	// console.log({ isLastMessage });

	const handleCopyClick = (textToCopy: string) => {
		navigator.clipboard.writeText(textToCopy).catch(err => console.error('Failed to copy: ', err));
	};

	return (
		<div className='message-container bot'>
			<div className='icon'>
				<img src='/images/white_logo.png' alt='ai logo' />
			</div>
			<div className={`message bot-message`}>
				<Markdown
					children={response}
					remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
					components={{
						ul({ node, ...props }) {
							return (
								<ul style={{ listStyle: 'none' }} {...props} />
							);
						},
						p({ node, className, children, ...props }) { return <p style={{ fontSize: "16px", lineHeight: "24px" }}>{children}</p>; },
						li({ node, className, children, ...props }) { return <li style={{ fontSize: "16px", lineHeight: "24px" }}>{children}</li>; },
						// p({ node, className, children, ...props }) { return <code><TypewriterDG text={String(children).replace(/\n$/, '')} /></code>; },
						// h1({ node, className, children, ...props }) { return <TypewriterDG text={String(children).replace(/\n$/, '')} />; },
						// h2({ node, className, children, ...props }) { return <TypewriterDG text={String(children).replace(/\n$/, '')} />; },
						// h3({ node, className, children, ...props }) { return <h3><TypewriterDG text={String(children).replace(/\n$/, '')} /></h3>; },
						// h4({ node, className, children, ...props }) { return <TypewriterDG text={String(children).replace(/\n$/, '')} />; },
						// h5({ node, className, children, ...props }) { return <TypewriterDG text={String(children).replace(/\n$/, '')} />; },
						// h6({ node, className, children, ...props }) { return <TypewriterDG text={String(children).replace(/\n$/, '')} />; },
						code({ node, className, children, ...props }) {
							const match = /language-(\w+)/.exec(className || '')

							return match ? (
								<div className="code-container">
									<div className="CopyButtonClass" onClick={() => handleCopyClick(String(children))}>
										<FontAwesomeIcon icon={faCopy} />
										<p className='noselect'>Copy</p>
									</div>
									<SyntaxHighlighter
										children={String(children)}
										style={dracula}
										language={match[1]}
										PreTag="div"
									/>
								</div>
							) : (
								<code>{String(children)}</code>
								// 	isLastMessage
								// 		? <TypewriterDG text={String(children)} />
								// 		:
								// 		<code className={className} {...props}>
								// 			{children}
								// 		</code>
							)
						}
					}}
				/>
			</div>
		</div>
	)
}

export default BotMessageResponse
