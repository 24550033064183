import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchEventSource } from "@microsoft/fetch-event-source";
import AppSpinner from 'components/app-spinner';
import { ConversationMessage } from 'contracts/IConversationMessage';
import { MessageResponseDto } from 'contracts/iMessageResponse';
import { UserPrompt } from 'contracts/userPromptInterface';
import { ACCESS_TOKEN } from 'lib/constants';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveMessageResponse } from 'services/apis/chatAiService';
import { getItemFromStorage } from 'services/storage/localStorageService';
import { RootState } from 'store';
import { updateUserConversation } from 'store/reducers/conversationReducer';
import AboutAiComponent from '../aboutAiComponent';
import BotMessageResponse from './BotMessageResponse';
import ChatTopBar from './ChatTopBar';
import UserMessageQuery from './UserMessageQuery';


const ChatAiPageChatSection: React.FC<{ showSidebar: any, sidebar: boolean }> = ({ showSidebar, sidebar }) => {
	const dispatch = useDispatch<any>();
	const userPromptRef = useRef<HTMLTextAreaElement>(null);

	const { activeConversationId, activeConversation, fetchingConversation } = useSelector((state: RootState) => state.conversations);
	const { user } = useSelector((state: RootState) => state.auth);

	const [userPrompt, setUserPrompt] = useState('');
	const [sendingPrompt, setSendingPrompt] = useState(false);
	const [responseData, setResponseData] = useState("");
	const [tempUserPrompt, setTempUserPrompt] = useState('');

	useEffect(() => {
		if (userPromptRef.current) {
			if (userPromptRef.current.scrollHeight < 126) {
				userPromptRef.current.style.height = 'auto';
				userPromptRef.current.style.height = userPromptRef.current.scrollHeight + 'px';
			} else if (userPromptRef.current.scrollHeight >= 126 && userPrompt.length) {
				userPromptRef.current.style.height = '126px';
			} else {
				userPromptRef.current.style.height = '54px';
			}
		}
	}, [userPrompt]);

	const messagesEndRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const scrollIntoView = () => {
			if (responseData !== '') {
				messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
			}
		};

		const timer2 = setTimeout(scrollIntoView, 1);
		return () => {
			clearTimeout(timer2);
		};
	}, [responseData]);

	const initialEndRef = useRef<boolean>(true);
	useEffect(() => {
		const scrollIntoView = () => {
			if (initialEndRef.current) {
				messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
				initialEndRef.current = false;
			}
		};
		const timer2 = setTimeout(scrollIntoView, 1);
		return () => {
			clearTimeout(timer2);
		};
	}, [initialEndRef]);

	const sendUserPrompt = async (prompt: string = "") => {
		if (!userPrompt.length && prompt === "") return;
		let msg = prompt || userPrompt;
		setTempUserPrompt(msg);
		setUserPrompt('');
		try {
			setSendingPrompt(true);

			const promptData: UserPrompt = {
				message: msg,
				conversationId: activeConversationId,
				userId: user?.id!,
				conversationHistory: activeConversation?.conversationMessages || [],
			};

			let first: boolean = true;
			let conversationMessageId = 0;
			let conversation: any;
			let _responseData = "";
			await fetchEventSource(`${process.env.REACT_APP_API_URL}/conversationMessages/chat`, {
				method: "POST",
				headers: {
					Accept: "text/event-stream",
					"Content-Type": "application/json",
					Authorization: `Bearer ${getItemFromStorage(ACCESS_TOKEN)!}`,
				},
				body: JSON.stringify(promptData),
				async onopen(res) {
					setResponseData('');
					if (res.ok && res.status === 200) {
					} else if (
						res.status >= 400 &&
						res.status < 500 &&
						res.status !== 429
					) {
						console.log("Client side error ", res);
						setSendingPrompt(false);
					}
				},
				onmessage(event) {
					const data = JSON.parse(event.data);

					if (first && data.eventData === '') {
						conversationMessageId = data.conversationMessage.messageResponse.id;
						conversation = data;
						first = false;
					} else {
						if (data.final) {
						} else if (!data.final && data.eventData !== '' && data.eventData !== null) {
							_responseData += data.eventData;
							setResponseData(prevResponseData => prevResponseData + data.eventData);
						}
					}
				},
				async onclose() {
					await handleSaveMessageResponse({
						conversationMessageId: conversationMessageId,
						responseMessage: _responseData
					}, conversation);
				},
				onerror(err) {
					console.log("There was an error from server", err);
					setSendingPrompt(false);
					handleSaveMessageResponse({
						conversationMessageId: conversationMessageId,
						responseMessage: _responseData
					}, conversation);
					return;
				},
			});
		} catch (error) {
			console.error("Error sending prompt:", error);
		} finally {
			setTempUserPrompt('')
			setResponseData('');
			setSendingPrompt(false);
		}
	};

	const handleSaveMessageResponse = async (payload: MessageResponseDto, conversation: any) => {
		saveMessageResponse(payload).then(res => {
			setSendingPrompt(false);
			setTempUserPrompt('');

			conversation.conversationMessage.messageResponse.response = payload.responseMessage;
			dispatch(updateUserConversation({ conversationId: conversation.conversationMessage.conversationId, response: conversation.conversationMessage }));
		}).catch(e => console.log(e))
	}

	return (
		<div className='chat-page'>
			<ChatTopBar sidebar={sidebar} showSidebar={showSidebar} />
			<div className="chatlist" key={11}>
				{
					!fetchingConversation ?
						(((activeConversation != null && activeConversationId !== null) || sendingPrompt)
							? <div className='messages'>
								{
									true && activeConversation?.conversationMessages.map((message: ConversationMessage, indexMessages: number) => {
										return (
											<div key={indexMessages} style={{ width: "100%" }}>
												<UserMessageQuery message={message.message} />
												<BotMessageResponse response={message.messageResponse.response} isLastMessage={false} />
											</div>
										);
									})
								}
								{sendingPrompt && <div style={{ width: "100%" }}>
									<UserMessageQuery message={tempUserPrompt} />
									<BotMessageResponse response={responseData} isLastMessage={true} />
								</div>}
								<div ref={messagesEndRef} />
							</div>
							: <AboutAiComponent sendUserPrompt={sendUserPrompt} />) : <AppSpinner color='#333' />
				}
			</div>
			<div className="message-bar">
				<textarea
					value={userPrompt}
					ref={userPromptRef}
					className='message-area'
					placeholder="Enter your prompt..."
					rows={1}
					onChange={(e) => setUserPrompt(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === 'Enter' && !e.shiftKey && !sendingPrompt) {
							e.preventDefault();
							sendUserPrompt();
						}
					}}
				>
				</textarea>
				<div
					className='send-message'
					onClick={() => sendingPrompt ? null : sendUserPrompt()}
				>
					{sendingPrompt ? (
						<AppSpinner />
					) : (
						<FontAwesomeIcon className='icon' icon={faArrowRight} color='#fff' aria-disabled={userPrompt === ''} />
					)}
				</div>
			</div>
		</div>
	)
}

export default ChatAiPageChatSection;