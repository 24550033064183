import { RegistrationRequest } from 'contracts/IRegistrationRequest';
import { AuthenticationRequest } from "contracts/authenticationRequestInterface";
import { ACCESS_TOKEN, LOGIN_USER, REFRESH_TOKEN } from 'lib/constants';
import { AuthenticationResponse } from '../../contracts/authenticationResponseInterface';
import { addItemToStorage, addObjectToStorage, getItemFromStorage } from '../storage/localStorageService';
import { getRequest, postRequest, removeAuthToken, setAuthToken } from "./baseRequests";

const controllerEndPoint: string = '/auth';

export const loginUserApi = async (authenticationRequest: AuthenticationRequest) => {
    removeAuthToken();
    return await postRequest(`${controllerEndPoint}/login`, authenticationRequest);
}

export const registerUser = async (registerUser: RegistrationRequest) => {
    removeAuthToken();
    return await postRequest(`${controllerEndPoint}/register`, registerUser);
}

export const loginUserFromGoogleAuth = async (registerUser: RegistrationRequest) => {
    removeAuthToken();
    return await postRequest(`${controllerEndPoint}/loginGoogle`, registerUser);
}

export const getUserDetailFromGoogleAuth = (accessToken: string) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return getRequest(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`);
}

export const setAuthDetails = (data: AuthenticationResponse) => {
    addItemToStorage(ACCESS_TOKEN, data.accessToken);
    addItemToStorage(REFRESH_TOKEN, data.refreshToken);
    addObjectToStorage(LOGIN_USER, data.user);
}