import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGoogleLogin } from '@react-oauth/google';
import { RegistrationRequest } from 'contracts/IRegistrationRequest';
import { CHAT_AI_PAGE, HOME_PAGE } from 'lib/constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserDetailFromGoogleAuth, loginUserFromGoogleAuth } from 'services/apis/authService';
import { handleApiError } from 'services/apis/baseRequests';
import { loginUser } from 'store/reducers/authReducer';

const GoogleLoginIcon: React.FC<{ loading: boolean; setLoading: any }> = ({ loading, setLoading }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const googleLogin = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            try {
                setLoading(true);
                let res = await getUserDetailFromGoogleAuth(codeResponse.access_token);

                let d: RegistrationRequest = {
                    email: res.data.email,
                    password: res.data.id,
                    firstName: res.data.given_name,
                    lastName: res.data.family_name,
                    isGoogleAccountLogin: true,
                    phoneNumber: '',
                    profileImage: res.data.picture
                };
                let response = await loginUserFromGoogleAuth(d);
                dispatch(loginUser(response.data));
                navigate(HOME_PAGE);
            } catch (error: any) {
                handleApiError(error);
            } finally {
                setLoading(false);
            }
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    return (
        <button onClick={() => { googleLogin() }} type='button' className='icon_cta_button'><FontAwesomeIcon className='icon' icon={faGooglePlusG} />Google</button>
    )
}

export default GoogleLoginIcon