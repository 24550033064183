import React from 'react'
import './LoaderSpinner.scss'

export const LoaderSpinner: React.FC<{ text?: string }> = ({ text }) => {
    return (
        <div className={`loading-spinner`}>
            <div className="loader">
                <img src="./ring-loader.svg" alt="loader" />
                <h4>{text || 'Loading...'}</h4>
            </div>
        </div>
    )
}
