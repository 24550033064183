import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { User } from "contracts/userInterface";
import { ACCESS_TOKEN, LOGIN_USER, REFRESH_TOKEN } from "lib/constants";
import { clearAuthStorage, getLoggedUserName } from "lib/utils";
import { setAuthDetails } from "services/apis/authService";
import { setAuthToken } from "services/apis/baseRequests";
import { getItemFromStorage, getObjectFromStorage } from "services/storage/localStorageService";
import { IAuthInitialState } from "../types/i-authenticated-state";
import { AuthenticationResponse } from "contracts/authenticationResponseInterface";

const initialState: IAuthInitialState = {
	user: null,
	isLoggedIn: false,
	accessToken: null,
	refreshToken: null,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		checkLoginStatus: (state) => {
			const user: User = getObjectFromStorage(LOGIN_USER);
			if (user) {
				state.isLoggedIn = true;
				state.user = user;
				state.accessToken = getItemFromStorage(ACCESS_TOKEN);
				state.refreshToken = getItemFromStorage(REFRESH_TOKEN);
				setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
			} else {
				state.isLoggedIn = false;
				clearAuthStorage();
			}
		},
		loginUser: (state, action) => {
			let data: AuthenticationResponse = action.payload;
			setAuthDetails(data);
			state.isLoggedIn = true;
			state.user = data.user;
			state.accessToken = data.accessToken;
			state.refreshToken = data.refreshToken;
			setAuthToken(data.accessToken);
			toast.success(`Login Successful, Welcome ${getLoggedUserName()}!`);
		},
		setLoginUser: (state, action) => {
			state.user = action.payload.user;
			console.log("causing issue?");
		},
		logout: (state) => {
			state.user = null;
			state.isLoggedIn = false;
			localStorage.clear();
		},
	},
});

export const { checkLoginStatus, loginUser, logout, setLoginUser } = userSlice.actions;

export default userSlice.reducer;
