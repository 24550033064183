import React, { useEffect, useRef } from 'react'

interface ItemActionProps {
	handleOptionClick: (option: string) => void;
	setActiveItem: React.Dispatch<React.SetStateAction<number>>;
	setMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminItemActions: React.FC<ItemActionProps> = ({ handleOptionClick, setActiveItem, setMenuVisible }) => {

	const menuRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
			setActiveItem(-1);
			setMenuVisible(false);
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			ref={menuRef}
			style={{
				position: 'absolute',
				top: '0%',
				right: '60px',
				backgroundColor: 'white',
				border: '1px solid #ccc',
				borderRadius: '4px',
				boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
				zIndex: 1
			}}>
			<div style={{ padding: '8px 16px', cursor: 'pointer', textAlign: 'left' }} onClick={() => handleOptionClick('View')}>View</div>
			<div style={{ padding: '8px 16px', cursor: 'pointer', textAlign: 'left' }} onClick={() => handleOptionClick('Edit')}>Edit</div>
			<div style={{ padding: '8px 16px', cursor: 'pointer', textAlign: 'left' }} onClick={() => handleOptionClick('Delete')}>Delete</div>
		</div>
	)
}

export default AdminItemActions