import React from 'react'
import { HeroSection } from 'contracts/homeInterface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Particles from "react-tsparticles";
import loadFull from "react-tsparticles";

import header_main_img from 'assets/header_main_img.png';
import './hero_section.scss'
import type { Engine } from '@tsparticles/engine';
import particleJson from './particles.json'

const HeroSectionComponent: React.FC<{ heroSection: HeroSection }> = ({ heroSection }) => {
	const particlesInit = async (engine: Engine): Promise<void> => {
		console.log(engine);
		// await new loadFull(engine);
	};

	return (
		<section className="hero-section noselect">
			{/* <Particles
				id="tsparticles"
				init={particlesInit}
				options={{
					"fullScreen": {
						"enable": true,
						"zIndex": 1
					},
					"particles": {
						"number": {
							"value": 10,
							"density": {
								"enable": false,
								"value_area": 800
							}
						},
						"color": {
							"value": "#fff"
						},
						"shape": {
							"type": "star",
							"options": {
							}
						},
						"opacity": {
							"value": 0.8,
							"random": false,
							"anim": {
								"enable": false,
								"speed": 1,
								"opacity_min": 0.1,
								"sync": false
							}
						},
						"size": {
							"value": 4,
							"random": false,
							"anim": {
								"enable": false,
								"speed": 40,
								"size_min": 0.1,
								"sync": false
							}
						},
						"rotate": {
							"value": 0,
							"random": true,
							"direction": "clockwise",
							"animation": {
								"enable": true,
								"speed": 5,
								"sync": false
							}
						},
						"line_linked": {
							"enable": true,
							"distance": 600,
							"color": "#ffffff",
							"opacity": 0.4,
							"width": 2
						},
						"move": {
							"enable": true,
							"speed": 2,
							"direction": "none",
							"random": false,
							"straight": false,
							"out_mode": "out",
							"attract": {
								"enable": false,
								"rotateX": 600,
								"rotateY": 1200
							}
						}
					},
					"interactivity": {
						"events": {
							"onhover": {
								"enable": true,
								"mode": [
									"grab"
								]
							},
							"onclick": {
								"enable": false,
								"mode": "bubble"
							},
							"resize": true
						},
						"modes": {
							"grab": {
								"distance": 400,
								"line_linked": {
									"opacity": 1
								}
							},
							"bubble": {
								"distance": 400,
								"size": 40,
								"duration": 2,
								"opacity": 8,
								"speed": 3
							},
							"repulse": {
								"distance": 200
							},
							"push": {
								"particles_nb": 4
							},
							"remove": {
								"particles_nb": 2
							}
						}
					},
					"retina_detect": true,
					"background": {
						"color": "#111",
						"image": "",
						"position": "50% 50%",
						"repeat": "no-repeat",
						"size": "cover"
					}
				}}
			/> */}
			<div className="news-bar">
				<div className="news">News</div>
				<div>We've launched our Chat bot for developers</div>
			</div>
			<h1 className="headline">{heroSection.headline}</h1>
			<p className="sub-heading">{heroSection.subHeading}</p>
			<div className="header-btns">
				<div className="get-started-btn">
					Get Started <FontAwesomeIcon icon={faArrowRight} className="gsb-icon" />
				</div>
				<div className="learn-more-btn">
					Learn More
				</div>
			</div>
			<div className="header-main-img">
				<img src={header_main_img} alt="temp"></img>
			</div>
			<div className='hidden-block'></div>
		</section>
	)
}

export default HeroSectionComponent