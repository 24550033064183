import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parallaxImage from 'assets/parallax.jpg';
import React from 'react';
import './breadcrumbs_container.scss';

interface BreadCrumbProps {
	title: string;
	description: string;
	activePageName: string;
	activePageUrl: string;
}

const BreadcrumbsContainer: React.FC<BreadCrumbProps> = ({ title, description, activePageName, activePageUrl }) => {
	return (
		<div className='breadcrumb-container' style={{ backgroundImage: `url(${parallaxImage})` }}>
			<div className='breadcrumb-content'>
				<div className='title'>{title}</div>
				<div className='description'>{description}</div>
				<div className='active-indicator'>
					<span><a href='/'>Home</a></span>
					<span><FontAwesomeIcon icon={faAngleRight} className='icon-fix' /></span>
					<span><a href={activePageUrl}>{activePageName}</a></span>
				</div>
			</div>
		</div>
	)
}

export default BreadcrumbsContainer