import { ACCESS_TOKEN } from 'lib/constants';
import { getItemFromStorage } from '../storage/localStorageService';
import { putRequest, setAuthToken } from "./baseRequests";
import { UpdateProfileRequest } from 'contracts/userInterface';

const controllerEndPoint: string = '/users';

export const updateUserProfile = async (updateProfileRequest: UpdateProfileRequest) => {
    setAuthToken(getItemFromStorage(ACCESS_TOKEN)!);
    return await putRequest(`${controllerEndPoint}/updateUserProfile`, updateProfileRequest);
}
