import { faLaptopCode, faMobileScreen, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoaderSpinner } from 'components/loader-spinner/LoaderSpinner';
import { AIChatExamples } from 'contracts/aIChatExamplesInterface';
import { features, limitations, messages } from 'lib/chat-ai-page-data';
import { useEffect, useState } from 'react';
import { getAiChatExamples } from 'services/apis/homeService';

interface AboutAiComponentProps {
	sendUserPrompt: (prompt: string) => void;
}

const AboutAiComponent: React.FC<AboutAiComponentProps> = ({ sendUserPrompt }) => {
	const [loadingContent, setLoadingContent] = useState(true);
	const [aiChatExamples, setAiChatExamples] = useState<AIChatExamples | null>(null);

	useEffect(() => {
		getAiChatExamples()
			.then((res) => {
				let data = res.data as AIChatExamples;
				setAiChatExamples(data);
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setTimeout(() => {
					setLoadingContent(false);
				}, 1000);
			});
	}, []);

	return (
		loadingContent
			? <LoaderSpinner />
			: <div className='chat-section-intro noselect'>
				<div className='headline'>
					<div className='headline-top'>Welcome to DevGene's AI Chat Bot - Your AI Development Partner and Expert AI Assistant for Developers</div>
					<div className='headline-bottom'>Just ask for code snippets, tips, or guidance, and let's make your ideas become a reality!</div>
				</div>
				<div className='about-ai'>
					<div className="card">
						<FontAwesomeIcon className='icon' icon={faPuzzlePiece} />
						<h3 className='about-title'>Game Development</h3>
						<div className='prompt-container cpointer'>
							{
								aiChatExamples?.gameDevelopmentExamples.map((message, index) => {
									return <div className='msg' key={index}
										onClick={() => {
											sendUserPrompt(message);
										}}
									>
										<p>{message}</p>
									</div>;
								})
							}
						</div>
					</div>
					<div className="card features">
						<FontAwesomeIcon className='icon' icon={faMobileScreen} />
						<h3 className='about-title'>App Development</h3>
						<div className='prompt-container cpointer'>
							{
								aiChatExamples?.appDevelopmentExamples.map((message, index) => {
									return <div className='msg' key={index}
										onClick={() => {
											sendUserPrompt(message);
										}}
									>
										<p>{message}</p>
									</div>;
								})
							}
						</div>
					</div>
					<div className="card limitations">
						<FontAwesomeIcon className='icon' icon={faLaptopCode} />
						<h3 className='about-title'>Web Development</h3>
						<div className='prompt-container cpointer'>
							{
								aiChatExamples?.webDevelopmentExamples.map((message, index) => {
									return <div className='msg' key={index}
										onClick={() => {
											sendUserPrompt(message);
										}}
									>
										<p>{message}</p>
									</div>;
								})
							}
						</div>
					</div>
				</div>
			</div>
	)
}

export default AboutAiComponent