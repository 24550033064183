import { faBars, faRightToBracket, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserAvatar from "components/user-avatar";
import { CHAT_AI_PAGE } from "lib/constants";
import { truncateString } from "lib/utils";
import { deleteConversationByConversationId, getConversationMessagesByConversationId } from "services/apis/chatAiService";
import { RootState } from "store";
import { deleteUserConversation, fetchConversationEnd, fetchConversationStart, getConversationTitles, setNewChatInReducer, setUserConversation } from "store/reducers/conversationReducer";
import { Conversation } from "store/types/iUserConversationContract";

const ChatAiPageSidebar: React.FC<{ showSidebar: any, sidebar: boolean }> = ({ showSidebar, sidebar }) => {
  const [searchText, setSearchText] = useState('');
  let _conversationTitles = useSelector((state: RootState) => state.conversations.titles);
  const [conversationTitles, setConversationTitles] = useState(_conversationTitles);


  const dispatch = useDispatch<any>();
  const { chatId } = useParams();

  useEffect(() => {
    setConversationTitles(_conversationTitles);
  }, [_conversationTitles]);

  useEffect(() => {
    dispatch(getConversationTitles());
    if (chatId) {
      setActiveConversation(parseInt(chatId), true);
    }
  }, [dispatch, chatId]);

  function setActiveConversation(conversationId: number, forceFetch: boolean): void {
    if (conversationId === -1) return;
    dispatch(fetchConversationStart());

    window.history.pushState({}, '', `${CHAT_AI_PAGE}/c/${conversationId}`);
    getConversationMessagesByConversationId(conversationId)
      .then((response) => {
        dispatch(setUserConversation(response.data));
      })
      .catch((error) => {
        console.log(error);
        window.history.pushState({}, '', `${CHAT_AI_PAGE}`);
      })
      .finally(() => {
        dispatch(fetchConversationEnd());
      })
  }

  const handleDeleteConversation = (conversationId: number) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this suggestion?"
    );
    if (confirmed) {
      window.history.replaceState({}, '', `${CHAT_AI_PAGE}`);
      dispatch(deleteUserConversation(conversationId));
      deleteConversationByConversationId(conversationId);
    }
  };

  const setNewChat = () => {
    window.history.pushState({}, '', `${CHAT_AI_PAGE}`);
    dispatch(setNewChatInReducer())
  }

  function searchChat(e: any): void {
    let searchTerm = e.target.value;
    setSearchText(searchTerm);

    setConversationTitles(
      searchTerm.length > 0
        ? conversationTitles.filter(c => c.conversationTitle.toLowerCase().includes(searchTerm.toLowerCase()))
        : _conversationTitles);
  }

  return (
    <div className="">
      <section className="sb-logo">
        <a href="/">
          <div className="icon-img">
            <img className="icon" src="/logo.png" alt="icon img " />
          </div>
        </a>
        <FontAwesomeIcon className='icon' onClick={() => showSidebar(!showSidebar)} icon={faRightToBracket} style={{ transform: 'rotate(180deg)' }} />
      </section>

      <div className="sidebar-inner">
        <div className="top"></div>
        <div className="newchat-btn" onClick={() => setNewChat()}>
          <span className="new-chat-span">+ </span>
          New chat
        </div>

        <div className="search-container">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input type="text" placeholder="Search..." className="search-field"
            spellCheck="true" value={searchText} onChange={(e) => searchChat(e)} />
        </div>
        <h4 className="convo-title">Your Conversations</h4>
        <div className="convo-title-container">
          {
            conversationTitles.length
              ?
              <div>
                {conversationTitles.filter(c => (c.conversationId !== -1 || c.conversationTitle !== 'New Chat')).map((title: Conversation, index) => (
                  <div key={index} className="chat-title" onClick={() => setActiveConversation(title.conversationId, false)}>
                    <p className="noselect">{truncateString(title.conversationTitle)}</p>
                    <FontAwesomeIcon icon={faTrash} className="del-chat" onClick={() => handleDeleteConversation(title.conversationId)} />
                  </div>
                ))}
              </div>
              : <div>No Conversations Found. Hit the New Chat button to start a new conversation.</div>
          }
        </div>
      </div>
      <div className="logged-user-container">
        <UserAvatar menuClass="bottom-dropdown-menu" fontSize={14} />
        {/* <FontAwesomeIcon icon={faBars} className="luc-icon" /> */}
      </div>

    </div>
  );
};

export default ChatAiPageSidebar;
