import { LoaderSpinner } from 'components/loader-spinner/LoaderSpinner';
import { HOME_PAGE } from 'lib/constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {

	const navigate = useNavigate();

	useEffect(() => {
		localStorage.clear();
		sessionStorage.clear();

		document.cookie.split(";").forEach((c) => {
			document.cookie = c
				.replace(/^ +/, "")
				.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
		});

		navigate(HOME_PAGE);
	}, [navigate]);

	return (
		<LoaderSpinner text='Logging Out User...' />
	)
}

export default LogoutPage