import Header from 'components/header/header';
import { LOGIN_USER } from 'lib/constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getObjectFromStorage } from 'services/storage/localStorageService';
import AuthInfo from './auth-info';
import LoginPage from './login';
import SignUpPage from './signup';
import './styles.scss';

const AuthPage = () => {
    const [showLogin, setShowLogin] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        if (getObjectFromStorage(LOGIN_USER)) {
            navigate('/chat-ai')
            return;
        }

        const storedForm = localStorage.getItem('authForm');
        if (storedForm) {
            setShowLogin(storedForm === 'login');
        }
    }, []);

    const handleToggleForm = () => {
        const newState = !showLogin;
        setShowLogin(newState);
        localStorage.setItem('authForm', newState ? 'login' : 'signup');
    };
    return (
        <div>
            <Header />
            <div className='auth-container'>
                <AuthInfo showLogin={showLogin} handleToggleForm={handleToggleForm} />
                <div className={`form-container `}>
                    <LoginPage showLogin={showLogin} />
                    <SignUpPage showLogin={showLogin} />
                </div>
            </div>
        </div>
    )
}

export default AuthPage