/* eslint-disable jsx-a11y/anchor-is-valid */
import { AxiosResponse } from 'axios';
import GoogleLoginIcon from 'components/google-login';
import RequestSendingLoader from 'components/request_sending_loader/RequestSendingLoader';
import { AuthenticationRequest } from 'contracts/authenticationRequestInterface';
import { AuthenticationResponse } from 'contracts/authenticationResponseInterface';
import { CHAT_AI_PAGE, HOME_PAGE } from 'lib/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUserApi } from 'services/apis/authService';
import { handleApiError } from 'services/apis/baseRequests';
import { loginUser } from 'store/reducers/authReducer';

const LoginPage: React.FC<{ showLogin: boolean; }> = ({ showLogin }) => {

	const initialFormState: AuthenticationRequest = {
		email: '',
		password: '',
		isGoogleAccountLogin: false
	};
	const dispatch = useDispatch();
	const [formData, setFormData] = useState(initialFormState);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (showLogin) {
			setFormData(initialFormState);
		}
	}, [showLogin]);

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		try {
			setLoading(true);
			let loginResponse: AxiosResponse<AuthenticationResponse> = await loginUserApi(formData);

			dispatch(loginUser(loginResponse.data));
			setFormData(initialFormState);
			navigate(HOME_PAGE);
		} catch (error: any) {
			handleApiError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<form onSubmit={(e: any) => {
			setFormData({ ...formData, isGoogleAccountLogin: false });
			handleSubmit(e);
		}} className={`${showLogin ? 'form-slide-in' : 'form-slide-out'}`}>
			<div className='auth-form-container'>
				<h1>Sign in</h1>
				{/* <div className="social-container">
				<GoogleLoginIcon loading={loading} />
			</div>
			<span>or use your account</span> */}
				<div className="infield">
					<label>Email</label>
					<input type="email" name="email" required value={formData.email} onChange={handleInputChange} />
				</div>
				<div className="infield">
					<label>Password</label>
					<input type="password" name='password' required value={formData.password} onChange={handleInputChange} />
				</div>
				{/* <a href="/" className="forgot">Forgot your password?</a> */}
				{loading
					? <RequestSendingLoader text='Signing in...' />
					: <div className='auth-btn-row'>
						<button type='submit' className='cta_button'>Sign In</button>
						<p className='or-text'>OR</p>
						<GoogleLoginIcon setLoading={setLoading} loading={loading} />
					</div>
				}
			</div>
		</form>
	)
}

export default LoginPage