import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import productCardImage from 'assets/assetstore_item.png'
import BreadcrumbsContainer from 'components/breadcrumbs-container/breadcrumbs_container'
import Footer from 'components/footer/footer'
import Header from 'components/header/header'
import { ProductResponse, SearchProductResponse } from 'contracts/IproductContract'
import { UserProductDownloadResponse } from 'contracts/IUserProductDownloadContract'
import { User } from 'contracts/userInterface'
import { ASSETS_STORE_ALL_PRODUCTS_PAGE, AUTH_PAGE, LOGIN_USER } from 'lib/constants'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAllActiveProducts, getProductCount, getUserTodayDownloadQuota, searchProduct } from 'services/apis/productService'
import { getObjectFromStorage } from 'services/storage/localStorageService'
import AssetProductCard from './components/AssetProductCard'
import AssetProductFilterComponent from './components/AssetProductFilterComponent'

const AssetStoreAllProductsPage = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [products, setProducts] = useState<ProductResponse[]>([]);
	const [searchProducts, setSearchProducts] = useState<SearchProductResponse[]>([]);
	const [userDownloadQuota, setUserDownloadQuota] = useState<UserProductDownloadResponse>();
	const [searchText, setSearchText] = useState('');
	const loggedInUser: User = getObjectFromStorage(LOGIN_USER);

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 6;
	const [totalRecords, setTotalRecords] = useState(0);
	const numOfPages = Math.ceil(totalRecords / itemsPerPage);
	const pageNumbers = Array.from({ length: numOfPages }, (_, i) => i + 1);

	const categoryFilterRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		getProductCount().then((res) => setTotalRecords(res.data))
	}, [])

	useEffect(() => {
		handleTodayDownloadQuotaFetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTodayDownloadQuotaFetch = () => {
		if (loggedInUser)
			getUserTodayDownloadQuota(loggedInUser.id).then((res) => setUserDownloadQuota(res.data.data))
	}

	const fetchProducts = (selectedCategories: number[], pageNum: number) => {
		let category = selectedCategories.join(',');

		getAllActiveProducts(pageNum, itemsPerPage, category)
			.then(response => setProducts(response.data.data))
			.catch(error => console.error('Error fetching products:', error));
	};

	useEffect(() => {
		fetchProducts([], 0)
	}, []);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const page = queryParams.get('page');
		if (page) {
			setCurrentPage(Number(page));
		}
	}, [location.search]);

	const updatePageInUrl = (page: number) => {
		const queryParams = new URLSearchParams(location.search);
		queryParams.set('page', page.toString());

		navigate({ search: queryParams.toString() });
		setCurrentPage(page);
	};

	const fetchSearchResults = async (query: string) => {
		if (query.length === 0) {
			setSearchProducts([]);
			return;
		}

		try {
			const response = await searchProduct(searchText);
			setSearchProducts(response.data);
		} catch (error) {
			console.error('Error fetching search results', error);
		}
	};

	useEffect(() => {
		fetchSearchResults(searchText);
	}, [searchText]);

	const handlePageClick = (pageNum: number) => {
		const params = new URLSearchParams(location.search);
		const categoryParams = params.get('category');
		const categoryArray = categoryParams ? categoryParams.split(',').map(Number) : [];

		updatePageInUrl(pageNum);
		fetchProducts(categoryArray, pageNum);
	}

	const routeToAssetPage = (productId: number) => {
		navigate(`${ASSETS_STORE_ALL_PRODUCTS_PAGE}/${productId}`)
	}

	const [isVisible, setIsVisible] = useState(false);
	const toggleVisibility = () => {
		setIsVisible(!isVisible);
	};

	return (
		<div className='all_assets_page'>
			<Header />
			<BreadcrumbsContainer activePageName='Tool Kit' activePageUrl='/assetstore/all' title='Tool Kits' description={'lorem10'} />
			<div className="all_assets_page_container">
				<AssetProductFilterComponent onFilterChange={fetchProducts} categoryFilterRef={categoryFilterRef} isVisible={isVisible} setIsVisible={setIsVisible} />
				<div className='all_assets_page_content'>
					<div className='assets_header'>
						<div className='search_filter'>
							<div className='search_filter_input'>
								<input type='text' id='search' placeholder='Search Tools Here' value={searchText} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)} />
								{/* <button className={`cta_button_outline ${searchText.length === 0 ? 'disabled' : 'primary_color'}`} disabled={searchText.length === 0} onClick={handleItemSearch}>Search</button> */}
							</div>
							{
								searchText !== ''
									? <div className='search_filter_content'>
										{
											searchProducts.length
												? searchProducts.map((product, index: number) => {
													return <div className='search_filter_item' key={index} onClick={() => routeToAssetPage(product.productId)}>
														<div className='item_image'>
															<img src={productCardImage} alt='pr' />
														</div>
														<div className='item_info'>
															<h4 className='item_title'>{product.productName}</h4>
															<p className='item_sub_title'>{product.categoryName}</p>
														</div>
													</div>
												})
												: <div>No Items Found</div>
										}
									</div>
									: null
							}
						</div>
						<div className='counter_n_filter'>
							<button onClick={toggleVisibility} type='button' className='hideen_btn icon_cta_button'><FontAwesomeIcon className='icon' icon={faFilter} /> Filter</button>
							<div className='daily_downloads_counter noselect'>
								<h5>Daily Downloads</h5>
								{
									loggedInUser
										? <div className='remaining_count'>
											<p>Remaining</p>
											<p>{userDownloadQuota?.todayDownloadsLeft}/{userDownloadQuota?.maxDownloadsAllowed}</p>
										</div>
										: <div className='login_msg'>
											<a href={AUTH_PAGE}>Please Login to View.</a>
										</div>
								}
							</div>
						</div>
					</div>
					<div className='top_assets_container all_page'>
						{products.length
							? products.map((product, index: number) => {
								return <AssetProductCard key={index} assetData={product} userDownloadQuota={userDownloadQuota!} setUserDownloadQuota={setUserDownloadQuota} />
							})
							: <div>No Products Found</div>
						}
					</div>
					<div className='pagination'>
						{/* <button onClick={goToNextPage}>Next Page</button> */}
						{
							pageNumbers.map((pageNum: number, index: number) => {
								return <div key={index} className={`page_num ${currentPage === pageNum ? 'active' : ''}`} onClick={() => handlePageClick(pageNum)}>{pageNum}</div>
							})
						}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default AssetStoreAllProductsPage;
