import React, { useState } from 'react'
import Header from 'components/header/header'
import Footer from 'components/footer/footer'
import BreadcrumbsContainer from 'components/breadcrumbs-container/breadcrumbs_container'
import { FAQ_PAGE } from 'lib/constants'

import './faq_page.scss'
import { initialFaqs } from './faq_data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

const FaqPage = () => {

	// , 'Developer Talent', 'Proposal Writer'
	const list: string[] = ['General', 'Learning Platform', 'AI Coding Assistant', 'Expert Support', 'Developer Toolkit'];

	const [activeCategory, setActiveCategory] = useState(list[0]);
	const [faqData, setFaqData] = useState(initialFaqs[activeCategory]);
	const [selectFaqQuestion, setSelectedFaqQuestion] = useState(0);
	console.log(selectFaqQuestion);


	const toggleFaq = (i: number) => {
		console.log(i);

		if (i === selectFaqQuestion) {
			setSelectedFaqQuestion(-1);
			return;
		}
		setSelectedFaqQuestion(i);
	}



	return (
		<div>
			<Header />
			<div className='faq-container' >
				<BreadcrumbsContainer
					title='Frequently Asked Questions'
					description='Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, corporis!'
					activePageName='FAQ'
					activePageUrl={FAQ_PAGE}
				/>
				<div className='faq-content' >
					<div className='faq-categories shadow'>
						<div className='title'>
							FAQ Categories
						</div>
						<div className='categories-container'>
							{list.map((item, index) => {
								return <p
									className={`${item === activeCategory ? 'active-category' : ''}`} key={index}
									onClick={() => {
										setActiveCategory(item);
										setFaqData(initialFaqs[item]);
										setSelectedFaqQuestion(0);
									}}
								>
									{item}
								</p>;
							})}
						</div>
					</div>
					<div className='faq-categories-questions'>
						{
							faqData.map((faq, index) => {
								return <div key={index} className='item'>
									<div className={`question ${index === selectFaqQuestion ? 'upper-border show' : ''}`} onClick={() => toggleFaq(index)}>
										<div>{faq.question}</div>
										<div><FontAwesomeIcon icon={index !== selectFaqQuestion ? faPlus : faMinus} className='faq-icon' /></div>
									</div>
									<div className={`answer ${index === selectFaqQuestion ? 'show shadow' : ''}`}>{faq.answer}</div>
								</div>
							})
						}
					</div>
				</div>
				<Footer />
			</div>
		</div>
	)
}

export default FaqPage