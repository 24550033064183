export const addItemToStorage = (itemName: string, value: any) => {
    localStorage.setItem(itemName, value);
}

export const addObjectToStorage = (itemName: string, value: any) => {
    localStorage.setItem(itemName, JSON.stringify(value));
}

export const getItemFromStorage = (itemName: string) => {
    return localStorage.getItem(itemName) || null;
}

export const getObjectFromStorage = (itemName: string) => {
    let object = localStorage.getItem(itemName);
    return object ? JSON.parse(object) : null;
}