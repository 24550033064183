import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthenticatedRoute from "./components/authenticated-route";
import { ASSETS_STORE_ALL_PRODUCTS_PAGE, ASSETS_STORE_PAGE, ASSETS_STORE_USER_VIEW_PRODUCT_PAGE, AUTH_PAGE, CHAT_AI_DETAIL_PAGE, CHAT_AI_PAGE, CHAT_DETAIL_PAGE, CONTACT_US_PAGE, COURSES_PAGE, DASHBOARD_HOME_PAGE, FAQ_PAGE, HOME_PAGE, LOGOUT_PAGE, USER_PROFILE_PAGE } from "./lib/constants";
import AuthPage from "./pages/authenticated/auth";
import ChatAiPage from "./pages/authenticated/chat_ai";
import CoursesPage from "./pages/shared/courses";
import PageNotFound from "./pages/shared/error/pageNotFound";
import HomePage from "./pages/shared/landing_page";
import { checkLoginStatus } from "./store/reducers/authReducer";
import ContactUsPage from "pages/shared/contact_us";
import FaqPage from "pages/shared/faq_page";
import LogoutPage from "pages/shared/logout_page";
import UserProfilePage from "pages/authenticated/admin/user_profile";
import AdminDashboard from "pages/authenticated/admin";
import AssetsStorePage from "pages/authenticated/assetsstore/AssetsStorePage";
import AssetStoreAllProductsPage from "pages/authenticated/assetsstore/AssetStoreAllProductsPage";
import UserViewAssetPage from "pages/authenticated/assetsstore/single_asset/UserViewAssetPage";

const aiChatroutes = [
  { path: CHAT_AI_PAGE, element: ChatAiPage },
  { path: CHAT_DETAIL_PAGE, element: ChatAiPage },
  { path: CHAT_AI_DETAIL_PAGE, element: ChatAiPage },
];


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLoginStatus());
  }, [dispatch]);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* End User Routes */}
          <Route path={HOME_PAGE} element={<HomePage />} />
          {aiChatroutes.map((route, index) => (<Route key={index} path={route.path} element={<AuthenticatedRoute component={route.element} />} />))}
          <Route path={USER_PROFILE_PAGE} element={<AuthenticatedRoute component={UserProfilePage} />} />
          <Route path={COURSES_PAGE} element={<CoursesPage />} />
          <Route path={AUTH_PAGE} element={<AuthPage />} />
          <Route path={LOGOUT_PAGE} element={<LogoutPage />} />
          <Route path={CONTACT_US_PAGE} element={<ContactUsPage />} />
          {/* <Route path={USER_PROFILE_PAGE} element={<UserProfilePage />} /> */}
          <Route path={FAQ_PAGE} element={<FaqPage />} />
          <Route element={<PageNotFound />} />

          {/* Assets Store */}
          <Route path={ASSETS_STORE_PAGE} element={<AssetsStorePage />} />
          <Route path={ASSETS_STORE_ALL_PRODUCTS_PAGE} element={<AssetStoreAllProductsPage />} />
          <Route path={ASSETS_STORE_USER_VIEW_PRODUCT_PAGE} element={<UserViewAssetPage />} />


          {/* Admin Dashboard Routes */}
          {/* <Route path={DASHBOARD_HOME_PAGE} element={<AdminDashboard />} />
          <AuthenticatedRoute component={AdminDashboard} /> */}
          <Route path={DASHBOARD_HOME_PAGE} element={<AuthenticatedRoute component={AdminDashboard} />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer newestOnTop={true} closeOnClick={true} pauseOnHover={false} autoClose={3000} />
    </div>
  );
}

export default App;
