import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import productCardImage from 'assets/assetstore_item.png';
import AppSpinner from 'components/app-spinner';
import { ProductResponse } from 'contracts/IproductContract';
import { UserProductDownloadResponse } from 'contracts/IUserProductDownloadContract';
import { User } from 'contracts/userInterface';
import { ASSETS_STORE_ALL_PRODUCTS_PAGE, LOGIN_USER } from 'lib/constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateUserDailyDownloadQuota } from 'services/apis/productService';
import { getObjectFromStorage } from 'services/storage/localStorageService';

interface AssetProductCardProps {
	assetData: ProductResponse;
	userDownloadQuota: UserProductDownloadResponse;
	setUserDownloadQuota: React.Dispatch<React.SetStateAction<UserProductDownloadResponse | undefined>>;
}

const AssetProductCard: React.FC<AssetProductCardProps> = ({ assetData, userDownloadQuota, setUserDownloadQuota }) => {
	const history = useNavigate();

	const [downloading, setDownloading] = useState<boolean>(false);
	const [downloadingItem, setDownloadingItem] = useState<number>(-1);
	const loggedInUser: User = getObjectFromStorage(LOGIN_USER);

	const handleDownloadClick = async (itemId: number, url: string) => {
		console.log(loggedInUser);

		if (loggedInUser) {
			if (userDownloadQuota.todayDownloadsLeft > 0) {
				// update call
				setDownloading(true);
				window.open(url, '_blank');
				setDownloadingItem(itemId);
				updateUserDailyDownloadQuota(loggedInUser.id, itemId)
					.then((res) => setUserDownloadQuota(res.data.data))
					.finally(() => {
						setDownloading(false);
						setDownloadingItem(-1);
					});
			} else {
				toast.error("Max Downloads Reached for today. Please wait for the next day.");
			}
		} else {
			toast.error("Please Login to Download a tool.");
		}
	};

	const routeToAssetPage = () => {
		history(`${ASSETS_STORE_ALL_PRODUCTS_PAGE}/${assetData.productId}`)
	}

	return (
		<div className='top_asset_item'>
			<img src={assetData.productThumbnailUrl} alt="product card" className="top_asset_item_img" />
			<div className="top_asset_item_info">
				<div>
					<h2 className="top_asset_item_name" onClick={routeToAssetPage}>{assetData.productName}</h2>
					<p className="top_asset_item_category">{assetData.categoryName}</p>
				</div>
				<div className="top_asset_item_downloads">
					<div className='download_count'>{assetData.downloadCount >= 1000 ? assetData.downloadCount / 1000 + 'K' : assetData.downloadCount} Downloads</div>
					{/* href={assetData?.productResourceFileUrl}  */}
					<a rel='noreferrer' onClick={() => handleDownloadClick(assetData.productId, assetData.productResourceFileUrl)}>
						<div className='download_btn'>
							<p>Download</p>
							<div className='download_btn_icon'>
								{downloading && downloadingItem === assetData.productId
									? <AppSpinner color='#3068a5' />
									: <FontAwesomeIcon icon={faDownload} size='1x' className='icon' />
								}
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	)
}

export default AssetProductCard