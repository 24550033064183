import Header from 'components/header/header';

import { faAngleRight, faEnvelope, faLocation, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CONTACT_US_PAGE } from 'lib/constants';
import './contact_us.scss';
import { useState, FormEvent } from 'react';
import Footer from 'components/footer/footer';
import BreadcrumbsContainer from 'components/breadcrumbs-container/breadcrumbs_container';
import RequestSendingLoader from 'components/request_sending_loader/RequestSendingLoader';
import { ContactUsRequest } from 'contracts/ISupportContract';
import { saveCustomerQuery } from 'services/apis/supportService';
import { toast } from 'react-toastify';

const ContactUsPage = () => {

	const [isSending, setIsSending] = useState(false);
	const [personName, setPersonName] = useState('');
	const [email, setEmail] = useState('');
	const [query, setQuery] = useState('');

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();
		setIsSending(true);

		const payload: ContactUsRequest = {
			personName,
			email,
			query,
		};

		console.log({ payload });
		saveCustomerQuery(payload)
			.then((res) => {
				console.log(res.data);
				toast.success(res.data.message);

				setPersonName('');
				setEmail('');
				setQuery('');
			})
			.catch((err) => {
				console.log(err);
				toast.success("Unable to update User Profile.")
			})
			.finally(() => {
				setIsSending(false);
			});
	};

	return (
		<div>
			<Header />
			<div className='contact-us-container'>
				<BreadcrumbsContainer
					title='Contact Us'
					description='Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat maxime mollitia officiis dolores fugiat enim quis recusandae fugit repellat?'
					activePageName='Contact Us'
					activePageUrl={CONTACT_US_PAGE}
				/>
				<div className='contact-us-content'>
					<div className='our-details shadow'>
						<div className='title'>
							Contact Details
						</div>
						<div className='details-box'>
							<div className='box'>
								<div className='box-title'>
									Office Address
								</div>
								<div className='info-container'>
									<div className='info-item'>
										<FontAwesomeIcon icon={faLocationDot} className='info-icon' />
										<p className='info-text'>25, Road L, Block E, Naval Anchorage, Islamabad</p>
									</div>
									{/* <div className='info-item'>
										<FontAwesomeIcon icon={faLocation} className='info-icon' />
										<p className='info-text'>Lorem ipsum dolor sit amet consectetur.</p>
									</div> */}
								</div>
							</div>
							<div className='divider'></div>
							<div className='box box-2'>
								<div className='box-title'>
									Contact Info
								</div>
								<div className='info-container'>
									<div className='info-item'>
										<FontAwesomeIcon icon={faPhone} className='info-icon' />
										<p className='info-text'>+92 303 7146617</p>
									</div>
									<div className='info-item'>
										<FontAwesomeIcon icon={faEnvelope} className='info-icon' />
										<p className='info-text'>devgene@ai.co</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='contact-us-form shadow'>
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<input type="text" name="name" placeholder="Enter your name*" value={personName}
									onChange={(e) => setPersonName(e.target.value)} required />
							</div>
							<div className="form-group">
								<input type="email" name="email" placeholder="Enter your email address*" value={email}
									onChange={(e) => setEmail(e.target.value)} required />
							</div>
							<div className="form-group">
								<textarea name="message" placeholder="Write your message" value={query}
									onChange={(e) => setQuery(e.target.value)} required></textarea>
							</div>
							<div className="form-group">
								{
									isSending
										? <RequestSendingLoader text='Sending Message...' />
										: <button className='cta_button' type="submit">Send Message</button>
								}
							</div>
						</form>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default ContactUsPage