import { logout } from 'store/reducers/authReducer';
import { type ClassValue, clsx } from "clsx"
import { getObjectFromStorage } from "services/storage/localStorageService";
import { twMerge } from "tailwind-merge"
import { AUTH_PAGE, LOGIN_USER } from "./constants";
import { User } from "contracts/userInterface";
import { NavigateFunction } from "react-router-dom";
import { toast } from 'react-toastify';


export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getLoggedUserName = () => {
  let user: User = getObjectFromStorage(LOGIN_USER);
  return user ? `${user.firstName} ${user.lastName}` : '';
}

export const getloginUserInitials = () => {
  let user: User = getObjectFromStorage(LOGIN_USER);
  return user ? `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}` : '';
}

export const getLoggedImage = () => {
  let user: User = getObjectFromStorage(LOGIN_USER);
  return user?.profileImage || '/images/white_logo.png';
}

export const clearAuthStorage = () => {
  localStorage.clear();
}

export const logoutUser = () => {
  localStorage.clear();
  window.location.href = AUTH_PAGE;
  toast.error("Session timeout. Please login again");
}

// This global function will handle navigation
export const navigateTo = (navigate: NavigateFunction, path: string) => {
  navigate(path);
};

export const truncateString = (text: string) => text.length <= 18 ? text : `${text.slice(0, 18)}...`;


// Date utils
export const formatDate = (dateString: string): string => {
  if (!dateString) return '';

  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const timeFormatter = new Intl.DateTimeFormat('en-US', options);
  const timeString = timeFormatter.format(date);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  const formattedDate = `${timeString} ${day}${getOrdinalSuffix(day)} ${month}, ${year}`;

  return formattedDate;
}

function getOrdinalSuffix(day: number): string {
  if (day > 3 && day < 21) return 'th'; // Handles 11th to 20th
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}