import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminItemActions from 'components/admin_item_actions/AdminItemActions';
import { CategoryResponse } from 'contracts/IcategoryContract';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteCategoryById, getAllCategories } from 'services/apis/categoryService';

interface AllCategoriesProps {
	setSubActiveTab: React.Dispatch<React.SetStateAction<string>>;
	setSelectedItemId: React.Dispatch<React.SetStateAction<number>>;
	onDeleteCategory: (categoryId: number) => void;
}

const AllCategoriesComponent: React.FC<AllCategoriesProps> = ({ setSubActiveTab, setSelectedItemId, onDeleteCategory }) => {

	const [categories, setCategories] = useState<CategoryResponse[]>([]);
	const [menuVisible, setMenuVisible] = useState(false);
	const [activeItem, setActiveItem] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getAllCategories();
				setCategories(response.data.data);
			} catch (error) {
				console.error('Error fetching categories:', error);
			} finally {
			}
		};

		fetchData();
	}, []);


	const toggleMenu = (index: number) => {
		setActiveItem(index)
		setMenuVisible(!menuVisible);
	};

	const handleOptionClick = (option: string) => {
		console.log(`Option selected: ${option}`);
		switch (option) {
			case 'View':
				setSelectedItemId(activeItem);
				setSubActiveTab('view-category')
				break;
			case 'Edit':
				setSelectedItemId(activeItem);
				setSubActiveTab('edit-category')
				break;
			case 'Delete':
				if (window.confirm("Are you sure you want to delete this?")) {
					console.log('yes');
					const copy = categories;
					setCategories(categories.filter(cat => cat.id !== activeItem))
					deleteCategoryById(activeItem)
						.then((res) => { toast.success(res.data.message); onDeleteCategory(activeItem); })
						.catch((error) => {
							toast.error(error.response.data.message);
							setCategories(copy);
						});
				}
				break;

			default:
				break;
		}
		setMenuVisible(false);
	};

	return (
		<div className="categories-data">
			<div className='table-title'>
				<h3>Categories</h3>
			</div>
			<table className='w100'>
				<thead className='w100'>
					<th className='w20'>ID</th>
					<th className='w60'>Name</th>
					<th className='w20 actions'>Actions</th>
				</thead>
				<tbody>
					{
						categories.map((category: CategoryResponse, index: number) => {
							return <tr key={index}>
								<td className='w20'>{category.id}</td>
								<td className='w60'>{category.name}</td>
								<td className='w20 actions'>
									<FontAwesomeIcon icon={faEllipsisVertical} className='icon' onClick={() => toggleMenu(category.id)} />
									{(menuVisible && activeItem === category.id) && (
										<AdminItemActions handleOptionClick={handleOptionClick} setActiveItem={setActiveItem} setMenuVisible={setMenuVisible} />
									)}
								</td>
							</tr>
						})
					}
				</tbody>
			</table>
		</div>
	)
}

export default AllCategoriesComponent