import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { getLoggedImage, getLoggedUserName } from 'lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faDashboard, faLock, faPerson, faUser } from '@fortawesome/free-solid-svg-icons'
import { DASHBOARD_HOME_PAGE, LOGOUT_PAGE, USER_PROFILE_PAGE } from 'lib/constants'

const UserAvatar: React.FC<{ fontSize?: number, classNames?: string, menuClass: string }> = ({ fontSize, classNames, menuClass }) => {

	const [dropDownOpen, toggleDropDown] = useState(false);
	const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {

		let dropdownToggleHandler = (e: MouseEvent) => {
			if (!dropdownMenuRef.current?.contains(e.target as Node)) {
				toggleDropDown(false);
			}
		}

		document.addEventListener('mousedown', dropdownToggleHandler);

		return () => {
			document.removeEventListener('mousedown', dropdownToggleHandler);
		}
	});

	const handleInvalidImageError = (event: any) => {
		event.target.src = '/images/white_logo.png';
	};

	return (
		<div className='user-avatar-container'>
			<div className={`user-avatar ${classNames || ''} flex-row-center`} onClick={() => toggleDropDown(!dropDownOpen)} ref={dropdownMenuRef}>
				<img src={getLoggedImage()} alt="avatar" onError={handleInvalidImageError} />
				<p style={{ fontSize: fontSize ? `${fontSize}px` : '18px' }} className="noselect ellipse-text">{getLoggedUserName()}</p>
			</div>
			<div className={`dropdown-menu ${menuClass} shadow ${dropDownOpen ? 'active' : 'inactive'}`}>
				<ul>
					<li className='dropdown-item'>
						<FontAwesomeIcon icon={faDashboard} className='di-icon' />
						<a href={DASHBOARD_HOME_PAGE}>Dashboard</a>
					</li>
					<li className='dropdown-item'>
						<FontAwesomeIcon icon={faUser} className='di-icon' />
						<a href={USER_PROFILE_PAGE}>View Profile</a>
					</li>
					<li className='dropdown-item'>
						<FontAwesomeIcon icon={faArrowRightFromBracket} className='di-icon' />
						<a href={LOGOUT_PAGE}>Logout</a>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default UserAvatar