import React from 'react'
import { TopOfferings } from 'contracts/homeInterface'
import './top_offerings.scss'

export const TopOfferingsComponent: React.FC<{ topOfferings: TopOfferings }> = ({ topOfferings }) => {
	return (
		<section className="top-offerings-outer">
			<div className="top-offerings">
				<div className="offering-item">{topOfferings.offering1}</div>
				<div className="offering-item">{topOfferings.offering2}</div>
				<div className="offering-item">{topOfferings.offering3}</div>
				<div className="offering-item">{topOfferings.offering4}</div>
			</div>
		</section>
	)
}
