import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import GoogleLoginIcon from 'components/google-login';
import RequestSendingLoader from 'components/request_sending_loader/RequestSendingLoader';
import { RegistrationRequest } from 'contracts/IRegistrationRequest';
import { AuthenticationResponse } from 'contracts/authenticationResponseInterface';
import { CHAT_AI_PAGE, HOME_PAGE } from 'lib/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { registerUser } from 'services/apis/authService';
import { handleApiError } from 'services/apis/baseRequests';
import { loginUser } from 'store/reducers/authReducer';

const SignUpPage: React.FC<{ showLogin: boolean; }> = ({ showLogin }) => {

	const initialFormState: RegistrationRequest = {
		email: '',
		password: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		isGoogleAccountLogin: false,
		profileImage: '',
	};
	const dispatch = useDispatch();
	const [formData, setFormData] = useState(initialFormState);
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		setFormData(initialFormState);
		if (showLogin) {
			setFormData(initialFormState);
		}
	}, [showLogin]);

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (formData.password === confirmPassword && !loading) {
			toast.info('Signing you up...!');
			setLoading(true);

			try {
				let signupResponse: AxiosResponse<AuthenticationResponse> = await registerUser(formData);
				dispatch(loginUser(signupResponse.data));
				toast.success('Signup Successful!');
				setFormData(initialFormState);
				navigate(HOME_PAGE);
			} catch (error: any) {
				handleApiError(error);
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<form onSubmit={handleSubmit} className={` ${showLogin ? 'form-slide-out' : 'form-slide-in'}`}>
			{/* <div className="social-container">
				<GoogleLoginIcon loading={loading} />
				</div> */}
			{/* <span>or use your email for registration</span> */}
			<div className='auth-form-container'>
				<h1>Create Account</h1>
				<div className="infield">
					<label>First Name</label>
					<input type="text" required name='firstName' value={formData.firstName} onChange={handleInputChange} />
				</div>
				<div className="infield">
					<label>Last Name</label>
					<input type="text" required name='lastName' value={formData.lastName} onChange={handleInputChange} />
				</div>
				<div className="infield">
					<label>Email</label>
					<input type="email" required name="email" value={formData.email} onChange={handleInputChange} />
				</div>
				<div className="infield">
					<label>Password</label>
					<input type="password" required name="password" minLength={6} value={formData.password} onChange={handleInputChange} />
				</div>
				<div className="infield">
					<label>Confirm Password</label>
					<input type="password" required name="confirmPassword" minLength={6} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
					{formData.password !== confirmPassword && <p className='errorText'>Passwords do not match</p>}
				</div>
				{
					loading
						? <RequestSendingLoader text='Signing up...' />
						: <div className='auth-btn-row'>
							<button type='submit' className='cta_button'>Sign Up</button>
							<p className='or-text'>OR</p>
							<GoogleLoginIcon setLoading={setLoading} loading={loading} />
						</div>
				}
			</div>
			{/* <div className="infield">
				<label>Phone Number</label>
				<input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} />
			</div> */}

		</form>
	)
}

export default SignUpPage