interface FAQ {
    question: string;
    answer: string;
}

interface FAQs {
    [category: string]: FAQ[];
}

export const initialFaqs: FAQs = {
    "General": [
        {
            "question": "What services are included in the monthly subscription?",
            "answer": "Our monthly subscription includes access to an AI-powered bot for coding and development assistance, a wide range of courses for web, app, and game development, various tools such as 3D models, UI templates, and web assets, and an 'Ask Expert' feature to get answers from industry experts."
        }
    ],
    "Learning Platform": [
        {
            "question": "What types of courses are available on your platform?",
            "answer": "We offer a comprehensive selection of courses tailored for developers, including web development, mobile app development, game development, and more. Our courses are designed to cater to all skill levels, from beginners to advanced developers."
        }
    ],
    "AI Coding Assistant": [
        {
            "question": "How does the AI bot help developers?",
            "answer": "The AI bot, powered by OpenAI, assists developers by providing code suggestions, debugging help, and development advice across various platforms including games, web apps, and more. It's designed to streamline your workflow and enhance your coding efficiency."
        }
    ],
    "Expert Support": [
        {
            "question": "How does the 'Ask Expert' feature work?",
            "answer": "The 'Ask Expert' feature allows subscribers to submit their questions directly to industry professionals. Whether you need detailed technical advice, project guidance, or troubleshooting help, our experts are here to provide you with insightful and actionable answers."
        }
    ],
    "Developer Talent": [
        {
            "question": "What is Developer Talent?",
            "answer": "Developer Talent connects you with skilled developers who can help you build and maintain your projects."
        },
        {
            "question": "How do I hire a developer?",
            "answer": "You can hire a developer by posting your project requirements on our platform and selecting from the list of available developers."
        }
    ],
    "Developer Toolkit": [
        {
            "question": "What tools can I access with my subscription?",
            "answer": "Subscribers have access to a diverse array of tools, including 3D models, UI templates, game assets, and web templates. These resources are curated to support your development projects and save you time and effort."
        }
    ],
    "Proposal Writer": [
        {
            "question": "What is the Proposal Writer?",
            "answer": "The Proposal Writer helps you create professional proposals by providing templates and automated writing assistance."
        },
        {
            "question": "How do I use the Proposal Writer?",
            "answer": "You can use the Proposal Writer by selecting a template and filling in the required information. The tool will help you format and polish your proposal."
        }
    ]
};
