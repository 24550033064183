import React, { useState } from 'react'
import './our_best_features.scss'
import { AboutUs } from 'contracts/homeInterface'
import ourBestFeaturesImg from 'assets/our-best-features-img.jpg';

const OurBestFeaturesComponent: React.FC<{ aboutUs: AboutUs }> = ({ aboutUs }) => {

	const [isFeatureHovered, setIsFeatureHovered] = useState(false);
	const handleFeatureMouseEnter = () => {
		setIsFeatureHovered(true);
	};

	const handleFeatureMouseLeave = () => {
		setIsFeatureHovered(false);
	};

	return (
		<section className="our-best-features">
			<div className="obf-container">
				<div className="obf-left">
					<img src={ourBestFeaturesImg} className="obf-img" alt="our best features" />
				</div>
				<div className="obf-right">
					<div className="header">
						<span className="tag">{aboutUs.tag} ✨</span>
						<h1 className="headline">{aboutUs.headline}</h1>
						<p className="subheading">{aboutUs.subHeading}</p>
					</div>
					{
						aboutUs.services.map((service, index) => {
							return <div key={index} className={`feature-card ${(index === 0 && !isFeatureHovered) ? 'feature-card--primary' : ''}`}
								onMouseEnter={index === 0 ? undefined : handleFeatureMouseEnter}
								onMouseLeave={index === 0 ? undefined : handleFeatureMouseLeave}
							>
								<div className="feature-content">
									<div className="feature-number">{index + 1}</div>
									<h2 className="feature-title">{service.headline}</h2>
								</div>
								<p className="feature-description">{service.text}</p>
							</div>
						})
					}
				</div>
			</div>
		</section>
	)
}

export default OurBestFeaturesComponent